import {ITabSwitchProps} from "./models/TabSwitchModels";
import "./TabSwitch.scss";

const TabSwitch = ({items}: ITabSwitchProps) => {


    return (
        <div className={"tab-switch"}>
            <ul>
                {items.map(item => {
                    return <li key={item.label} className={item.active ? "active" : ""} onClick={() => item.executeFunction()}>{item.label}</li>
                })}
            </ul>
        </div>
    )
}


export default TabSwitch;