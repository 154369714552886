import React, {useEffect, useState} from 'react';
import "./IntroPageView.scss";
import {IContent} from "../common/models/ContentModels";
import fetcher from "../tools/Fetcher";
import parse from 'html-react-parser';
import {IIntroNewsItemProps, INewsItem} from "../common/models/NewsModels";


const IntroNewsItem = ({newsItem}: IIntroNewsItemProps) => {

    return (
        <div className={"page-intro-news-item"}>
            <div className={"page-intro-news-item-header"}>
                {newsItem.header}
            </div>
            <div className={"page-intro-news-item-content"}>
                {parse(newsItem.content)}
            </div>
        </div>
    )
}

const IntroPageView = () => {
    const [content, setContent] = useState<IContent[]>([]);

    useEffect(() => {

        fetcher('/content/1')
            .then(response => response.json())
            .then(result => {
                setContent(result)
            })
    }, []);

    const getContent = (key: string) => {
        const item = content.find(x => x.key === key)
        if(item === undefined){
            return ""
        }
        return item.value;
    }

    const getWave = () => {

        return (
            <div className={"page-view-fader"}>
            <svg xmlnsXlink="http://www.w3.org/1999/xlink" id="wave"
                 style={{transform: "rotate(0deg)", transition: "0.3s"}}
                 viewBox="0 0 1440 270"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                        <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"/>
                        <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"/>
                    </linearGradient>
                </defs>
                <path
                    style={{transform:"translate(0, 0px)", opacity:"1" }}
                    fill="url(#sw-gradient-0)"
                    d="M0,27L40,45C80,63,160,99,240,99C320,99,400,63,480,40.5C560,18,640,9,720,45C800,81,880,162,960,198C1040,234,1120,225,1200,216C1280,207,1360,198,1440,162C1520,126,1600,63,1680,72C1760,81,1840,162,1920,175.5C2000,189,2080,135,2160,121.5C2240,108,2320,135,2400,139.5C2480,144,2560,126,2640,139.5C2720,153,2800,198,2880,180C2960,162,3040,81,3120,76.5C3200,72,3280,144,3360,148.5C3440,153,3520,90,3600,58.5C3680,27,3760,27,3840,54C3920,81,4000,135,4080,144C4160,153,4240,117,4320,121.5C4400,126,4480,171,4560,175.5C4640,180,4720,144,4800,130.5C4880,117,4960,126,5040,144C5120,162,5200,189,5280,202.5C5360,216,5440,216,5520,220.5C5600,225,5680,234,5720,238.5L5760,243L5760,270L5720,270C5680,270,5600,270,5520,270C5440,270,5360,270,5280,270C5200,270,5120,270,5040,270C4960,270,4880,270,4800,270C4720,270,4640,270,4560,270C4480,270,4400,270,4320,270C4240,270,4160,270,4080,270C4000,270,3920,270,3840,270C3760,270,3680,270,3600,270C3520,270,3440,270,3360,270C3280,270,3200,270,3120,270C3040,270,2960,270,2880,270C2800,270,2720,270,2640,270C2560,270,2480,270,2400,270C2320,270,2240,270,2160,270C2080,270,2000,270,1920,270C1840,270,1760,270,1680,270C1600,270,1520,270,1440,270C1360,270,1280,270,1200,270C1120,270,1040,270,960,270C880,270,800,270,720,270C640,270,560,270,480,270C400,270,320,270,240,270C160,270,80,270,40,270L0,270Z"/>
            </svg>
            </div>
        )
    }

  return (
    <div id="intro-page" className="page-view container-center intro-page-view">
        <div className={"background-image"}></div>
        <div className={"page-intro-center-content"}>
            <span className={"page-header-subtext"}>{getContent("location")}</span>
            <div className='page-header-text'>{getContent("title")}</div>
            <div className={"page-header-subcontent"}>
                <div>
                    <p className={"page-header-subtitle"}>
                        {getContent("subtitle")}
                    </p>
                    <div className={"page-header-controls"}>
                        <a className={"btn-transparent"} href={"#products"}>Rezervace</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default IntroPageView;
