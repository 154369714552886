import {IPriceContainerProps, PriceItem} from "./models/PriceContainerModels";
import "./PriceContainer.scss";
import {useEffect, useState} from "react";
import fetcher from "../tools/Fetcher";
import LoadingBar, {LoadingBarColor, LoadingBarSize} from "../tools/LoadingBar";


const PriceContainer = ({productId, productPriceOptionId, personId, voucherCode, startTime, defaultProductName}: IPriceContainerProps) => {
    const [productPrices, setProductPrices] = useState<PriceItem[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);


    useEffect(() => {

        setIsLoading(true);
        const searchParam: URLSearchParams = new URLSearchParams({
            productId: productId ? productId.toString() : "",
            productPriceOptionId: productPriceOptionId ? productPriceOptionId.toString() : "0",
            personId: personId ? personId.toString() : "",
            startTime: startTime ? startTime.toDateString() : "",
            voucherCode: voucherCode ? voucherCode : ""
        })

        fetcher(`/product/prices?${searchParam}`)
            .then(respond => respond.json())
            .then(result => {
                setIsLoading(false);
                setProductPrices(result);
            })
    }, [productId, productPriceOptionId, personId, voucherCode, startTime]);


    const totalPrice = () => {
        let totalPrice = 0;
        if(!productPrices || productPrices.length === 0){
            return totalPrice;
        }

        productPrices.forEach(item => {
            totalPrice += item.price;
        })

        return  totalPrice;
    }

    if(isLoading){
        return <div className={"price-container price-center"}>
            <LoadingBar loadingBarColor={LoadingBarColor.Black} loadingBarSize={LoadingBarSize.Small} />
        </div>
    }

    if(productPrices && productPrices.length === 1){
        return <div className={"price-container"}>
            <div className={"price-container-item price-bold"}>
                <span>{defaultProductName}</span>
                <span>{productPrices[0].price} Kč</span>
            </div>
        </div>
    }

    return <div className={"price-container"}>
        {productPrices && productPrices.length > 0 ?
            <span>
                {productPrices.map(item => {
                    return <div className={"price-container-item"}>
                        <span>{item.name}</span>
                        <span>{item.price} Kč</span>
                    </div>
                })}
                <div className={"price-container-item price-bold"}>
                    <span>Cena celkem</span>
                    <span>{totalPrice()} Kč</span>
                </div>
            </span>
            :
            ""
        }
    </div>
}

export default PriceContainer;