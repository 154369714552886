import React from 'react';
import "./InfoPageView.scss";
import TabManager, {TabContent} from '../common/TabManager';
import PersonView from "../common/PersonView";
import PhotoGallery, {PhotoGalleryConfiguration} from "../common/PhotoGallery";

function InfoPageView() {


  const tabManagerContent = () =>{
    const tabContentArray: TabContent[] = [];
    const beforeTab = new TabContent("Před masáží");

    beforeTab.content = (
        <>
          <p>
            <ul>
                <li>Nejíst minimálně 1h před masáží</li>
                <li>Doplnit tekutiny</li>
                <li>Osprchovat se</li>
                <li>Zajít si na wc</li>
                <li>Na začátku proběhne úvodní rozhovor, kde zjístíme vaše bolístky</li>
                <ul>
                    <li>Poúrazové stavy</li>
                    <li>Jste po operaci</li>
                    <li>Nemoci</li>
                </ul>
            </ul>
          </p>
        </>
    )

    const duringTab = new TabContent("V masérně");

    duringTab.content = (
      <p>
        <ul>
            <li>Sundejte si šperky (prsteny, řetízky)</li>
            <li>Svlékněte se do spodního prádla</li>
            <li>Sepněte si dlouhé vlasy</li>
            <li>Položte se na lehátko</li>
            <li>Nebojte se komunikovat s masérem</li>
            <ul>
                <li>Intenzita masáže</li>
                <li>Je Vám teplo zima</li>
                <li>Máte žízeň</li>
                <li>Potřebujete upravit polohu</li>
                <li>Potřebujete kapesníček</li>
            </ul>
        </ul>
      </p>
    )

    const afterTab = new TabContent("Po masáži");

    afterTab.content = (
        <p>
            <ul>
                <li>Opatrně vstávejte z lehátka</li>
                <li className={"tab-content-bold"}>Vyhnout se velké fyzické aktivitě (1 – 2 dny po masáži)</li>
                <li>Dostatek tekutin</li>
                <li>Odpočívat</li>
                <li>Nejíst těžká jídla</li>
            </ul>
        </p>
    )

    tabContentArray.push(beforeTab);
    tabContentArray.push(duringTab);
    tabContentArray.push(afterTab);

    return tabContentArray
  }



  return (
    <div id="info-page" className="info-page-view">
      <div className='info-page-content'>
            <div className='info-page-header page-header'>Jak na masáž</div>
            <div className={"info-page-tab-content"}>
              <TabManager tabContent={tabManagerContent()} />
            </div>
        </div>
        <div className={"image-absolute image-absolute-left"}>
            <img alt="mandala" src={"images/mandala.png"} />
        </div>
        <div className={"image-absolute image-absolute-right"}>
            <img alt="mandala" src={"images/mandala.png"} />
        </div>

        <PersonView/>
        <PhotoGallery configuration={PhotoGalleryConfiguration.SingleRow} sectionId={1}/>
        <PhotoGallery configuration={PhotoGalleryConfiguration.Overview} sectionId={2}/>
    </div>
  );
}

export default InfoPageView;
