import {IBucketProps} from "./models/BucketModels";
import "./Bucket.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark, faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";
import {ChangeEvent, useEffect, useState} from "react";
import AdvancedRadioButtons, {IAdvancedRadioButtonOptions} from "./AdvancedRadioButtons";
import ConsumerWizardForm from "./ConsumerWizardForm";
import {IShopItem} from "./models/ShopItemSelectorModels";
import {AdvancedInputType} from "./AdvancedInput";
import {
    BookingWizardStep,
    IConsumer,
    IValidationData,
    IValidationItem,
    ResponseTypes
} from "./models/BookingWizardModels";
import fetcher from "../tools/Fetcher";
import LoadingBar, {LoadingBarColor} from "../tools/LoadingBar";

enum BucketCurrentStep{
    BucketList = 0,
    Payment = 1,
    Confirmation = 2,
    Error = 3
}

interface ShopOrderItem{
    shopOrderId: number
    count: number
}

interface IBucketData{
    firstName?: string,
    lastName?: string,
    phone?: string,
    email?: string,
    consumer?: IConsumer,
    paymentType?: number,
    deliveryType?: number,
    shopOrderItems?: ShopOrderItem[]
}

const Bucket = ({shopItems, updateShopItems}: IBucketProps) => {
    const [currentStep, setCurrentStep] = useState<BucketCurrentStep>(BucketCurrentStep.BucketList);
    const [bucketData, setBucketData] = useState<IBucketData>({});
    const [formValidationData, setFormValidationData] = useState<IValidationData>({valid: true});
    const [loading, setLoading] = useState<boolean>(false);
    const [pickupBankPayment, setPickupBankPayment] = useState<boolean>(false);

    useEffect(() => {
        fetcher(`/setting/getAllowedSettings/EshopEnablePickupWhenBankPayment`)
            .then(response => {
                if(response.status === 200){
                    response.json()
                        .then((result: any) => {
                                setPickupBankPayment(result);
                            }
                        );
                }
            })
    }, []);

    const handleUpdateData = (name: string, value: any) => {
        setBucketData(bucketData => {
            if(name === "paymentType" && Number(value) === 1){
                return {...bucketData,
                    [name]:  value, ["deliveryType"]: 1 }
            }

            if(name === "paymentType" && Number(value) === 3 && !pickupBankPayment){
                return {...bucketData,
                    [name]:  value, ["deliveryType"]: 2 }
            }

            return {...bucketData,
            [name]: value}
        });
    }

    const validation = (consumer: IConsumer, paymentType: number | undefined, deliveryType: number | undefined) :boolean => {
        let validationData: IValidationData = {valid: true}
        let validationItems: IValidationItem[] = [];

        if(!consumer.email || !consumer.email?.includes("@", 1)
            || !consumer.email.split("@")[1].includes(".", 1)) {

            validationItems.push({
                name: "email",
                reason: "Neplatný email"
            })
        }
        if(!consumer.firstName) {
            validationItems.push({
                name: "firstName",
                reason: "Vyplňte jméno"
            })
        }
        if(!consumer.lastName) {
            validationItems.push({
                name: "lastName",
                reason: "Vyplňte příjmení"
            })
        }
        if(!consumer.phone) {
            validationItems.push({
                name: "phone",
                reason: "Neplatný telefon"
            })
        }
        if(!paymentType){
            validationItems.push({
                name: "paymentType",
                reason: "Vyberte"
            })
        }
        if(!deliveryType){
            validationItems.push({
                name: "deliveryType",
                reason: "Vyberte"
            })
        }

        validationData.items = validationItems;
        validationData.valid = validationItems.length == 0

        if(!validationData.valid){
            validationData.reason = "Vyplňte požadovana pole"
        }

        setFormValidationData(validationData);

        return validationData.valid;
    }

    const handleUpdateCount = (shopItem: IShopItem, event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if(value == ""){
        }
        if(!(/^\d+$/.test(value) || value === "")) {
            return;
        }
        updateShopItems(shopItem.shopItemId, Number(value));
    }

    const handleValueChange = (shopItem: IShopItem, value: number) => {
        if(shopItem.count)
            updateShopItems(shopItem.shopItemId, shopItem.count + value);
    }

    const handleRemoveBucketItem = (shopItem: IShopItem) => {
        updateShopItems(shopItem.shopItemId, 0);
    }

    const handleSubmit = () => {
        bucketData.consumer = {
            email: bucketData.email,
            firstName: bucketData.firstName,
            lastName: bucketData.lastName,
            phone: bucketData.phone
        }

        if(!validation(bucketData.consumer, bucketData.paymentType, bucketData.deliveryType)){
            return;
        }

        bucketData.shopOrderItems = []
        shopItems.forEach(item => {
            if(!bucketData.shopOrderItems)
                return
            bucketData.shopOrderItems.push({
                shopOrderId: item.shopItemId,
                count: item.count ? item.count : 0
            })
        })

        bucketData.paymentType = Number(bucketData.paymentType)
        bucketData.deliveryType = Number(bucketData.deliveryType)



        setLoading(true);
        fetcher("/shopItem", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bucketData)
        })
            .then(response => {
                setLoading(false)
                //refreshData();
                if(response.status === 200) {
                    setCurrentStep(BucketCurrentStep.Confirmation);
                }
                else{

                    setCurrentStep(BucketCurrentStep.Error);
                }
            });
    }

    const calculateItemPrice = (price: number, count?: number) => {
        if(!count){
            return <></>
        }

        return <span>{price * count}</span>
    }

    const getTotalPrice = () =>{
        let totalPrice = 0;

        shopItems.forEach(item => {
            totalPrice += (item.price * (item.count ? item.count : 0))
        })

        return totalPrice;
    }


    const content = () => {
        if(currentStep === BucketCurrentStep.Error){
            return <div className={"bucket-summary"}>
                Nastala neočekávána chyba, prosím zkuste to znova, nebo nás kontaktujte.
            </div>
        }

        if (currentStep === BucketCurrentStep.Confirmation){
            return <div className={"bucket-summary"}>
                Děkujeme za objednávku za nedlouho Vám na email příjde potvrzení.
                {bucketData.deliveryType === 1 ? "Pro vyzvednutí na pobočce nás prosím nejprve telefonicky kontaktujte." : ""}
            </div>
        }

        if(loading){
            return <LoadingBar loadingBarColor={LoadingBarColor.Red}/>
        }

        if(currentStep === BucketCurrentStep.Payment){
            return <div className={"bucket-summary"}>

                <div className={"price-container"}>
                    <div className={"price-container-item price-bold"}>
                        <span>Cena</span>
                        <span>{getTotalPrice()} Kč</span>
                    </div>
                </div>
                <ConsumerWizardForm
                    submit={handleSubmit}
                    validationData={formValidationData}
                    updateData={handleUpdateData}
                    emailValue={bucketData.email}
                    firstNameValue={bucketData.firstName}
                    lastNameValue={bucketData.lastName}
                    phoneValue={bucketData.phone}
                    paymentType={bucketData.paymentType}
                    deliveryEnabled={true}
                    deliveryType={bucketData.deliveryType}
                    deliveryPickupBankPayment={pickupBankPayment}
                    submitLabel={"Dokončit nákup"}
                />


            </div>
        }

        if(shopItems.length === 0){
            return <div>Košik je prázdný</div>
        }

        return <div className={"bucket-items"}>
            {shopItems.map(shopItem => {
                return <div className={"bucket-item"}>
                    <div className={"bucket-item-header"}>
                        {shopItem.name}
                    </div>
                    <div className={"bucket-item-controls"}>
                        <span className={"bucket-item-counter-controls"}>
                             <span className={"bucket-item-counter-minus"} onClick={() => handleValueChange(shopItem, -1)}>
                                <FontAwesomeIcon icon={faMinus} />
                            </span>
                        </span>
                        <span className={"bucket-item-counter"}>
                            <input type={"text"} value={shopItem.count} onChange={(event) => handleUpdateCount(shopItem, event)}/>
                        </span>
                        <span className={"bucket-item-counter-controls"}>
                            <span className={"bucket-item-counter-plus"} onClick={() => handleValueChange(shopItem, 1)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </span>
                        </span>
                        <span className={"bucket-item-price"}>{calculateItemPrice(shopItem.price, shopItem.count)} KČ</span>
                        <span className={"bucket-item-cancel"}>
                            <button className={"btn-transparent darker no-border"} onClick={() => handleRemoveBucketItem(shopItem)}><FontAwesomeIcon icon={faXmark} /></button>
                        </span>
                    </div>
                </div>
            })}
            <div></div>
            <div className={"price-container"}>
                <div className={"price-container-item price-bold"}>
                    <span>Celkem</span>
                    <span>{getTotalPrice()} Kč</span>
                </div>
            </div>
            <div className={"bucket-submit"}>
                <button className={"btn"} onClick={() => setCurrentStep(BucketCurrentStep.Payment)}>Pokračovat</button>
            </div>
        </div>
    }

    return <div className={"bucket"}>
        {content()}
    </div>
}
export default Bucket;