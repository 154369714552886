import "./LoadingBar.scss";

export enum LoadingBarColor{
    White = 0,
    Red = 1,
    Black = 2
}

export enum LoadingBarSize{
    Normal = 0,
    Small = 1,
}

export interface ILoadingBarProps {
    loadingBarColor: LoadingBarColor;
    loadingBarSize?: LoadingBarSize;
}

const LoadingBar = ({loadingBarColor, loadingBarSize = LoadingBarSize.Normal}: ILoadingBarProps) =>{

    let classes = "lds-dual-ring ";
    if(loadingBarColor === LoadingBarColor.Red){
        classes += "red"
    }
    else if(loadingBarColor === LoadingBarColor.Black){
        classes += "black"
    }

    if(loadingBarSize === LoadingBarSize.Small){
        classes += " small"
    }




    return <div className={classes}></div>
}

export default LoadingBar;