import "./ShopItemPageView.scss";
import ShopItemSelector from "../common/ShopItemSelector";



function ShopItemPageView() {

    return (
        <div className='page-view shop-page-view' id="shop">
            <div className={"page-header"}>
                Dárkové poukazy
            </div>

            <div className='page-content'>
                <ShopItemSelector />
            </div>
        </div>
        
    );
}

export default ShopItemPageView;
