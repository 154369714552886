import "./ModalNews.scss";
import {useEffect, useState} from "react";
import fetcher from "../tools/Fetcher";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmarkCircle} from "@fortawesome/free-regular-svg-icons"

interface ModalResponse {
    isActive: boolean,
    color?: string,
    image?: string
}

const ModalNews = () => {
    const [modalData, setModalData] = useState<ModalResponse>({isActive: false});
    const [showModal, setShowModal] = useState<boolean>(false)

    const setModalState = (show: boolean) => {
        setShowModal(show)
        if(show) {
            document.body.style.overflow = "hidden";
        }
        else{
            document.body.style.overflow = "auto";
        }
    }

    useEffect(() => {
        fetcher('/setting/getModalWindow')
            .then(response => response.json())
            .then(result => {
                setModalData(result);
                if(result.isActive){
                    setShowModal(true);
                }
            })
    }, []);

    if(!showModal){
        return <></>
    }

    return <div className={"modal-wrapper"}>
        <div className={"modal"}>
            <img alt={"Akce"} src={modalData.image}/>
            <div className={"modal-controls"} style={{color: modalData.color}} onClick={() => setModalState(false)}>
                <FontAwesomeIcon icon={faXmarkCircle} />
            </div>
        </div>

    </div>
}

export default ModalNews;