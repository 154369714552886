import Home from "./components/Home";
import CancelOrderForm from "./components/CancelOrderForm";
import GdprView from "./components/views/GdprView";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
    path: '/'
  },
  {
    element: <CancelOrderForm />,
    path: '/cancel-order-form/:order'
  },
  {
    element: <GdprView />,
    path: '/gdpr'
  }
];

export default AppRoutes;
