import {IProduct} from "./ProductSelectorModels";

export interface IPerson{
    personId: number;
    firstname: string;
    lastname: string;
    description: string;
    imagePath: string;
    personRank: IPersonRank;
}

export interface IPersonRank{
    personRankId: number
    name: string,
    priceAdjustement: number
}

export class Person implements IPerson{
    personId: number = 0;
    firstname: string;
    lastname: string;
    description: string;
    imagePath: string;
    personRank: IPersonRank;

    public constructor(personId: number, firstname: string, lastname: string, description: string, imagePath: string, personRank: IPersonRank){
        this.personId = personId;
        this.firstname = firstname;
        this.lastname = lastname;
        this.description = description;
        this.imagePath = imagePath;
        this.personRank = personRank;
    }
    public get name() : string {
        return `${this.firstname} ${this.lastname}`
    }

}

export interface ISelectPersonProps{
    person: Person,
    updateFunction?: Function,
    selectedPerson?: Person
}


export interface IPersonSelectorProps{
    product: IProduct,
    updateFunction: Function;
    increaseStepFunction: Function,
    person?: Person,
    additionalPerson?: Person
}