export interface IShopItem {
    shopItemId: number;
    name: string;
    shortDescription: string;
    longDescription: string;
    imagePath: string;
    price: number;
    count?: number;
    specialOffer?: boolean;
}

export interface IShopItemProps{
    shopItem: IShopItem,
    buyFunction: Function
    detailSelect: Function
}

export enum ShopView {
    Shop = 0,
    Bucket = 1
}