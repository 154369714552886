import {useParams} from "react-router-dom";
import AdvancedInput from "./common/AdvancedInput";
import {useState} from "react";
import "./CancelOrderForm.scss";
import fetcher from "./tools/Fetcher";
import {IValidationItem} from "./common/models/BookingWizardModels";


const CancelOrderForm = () => {
    const [code, setCode] = useState<string>("");
    const [order, setOrder] = useState<any>({});
    const [validationMsg, setValidationMsg]
        = useState<IValidationItem>();
    const [isCancelled, setIsCancelled] = useState<boolean>(false);
    const params = useParams();

    const handleGetOrder = () => {
        fetcher(`/order/check`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({"token": params.order, "code": code})
            })
            .then(response =>
            {
                if (response.status === 200){
                    response.json()
                        .then((result: any) => {
                            if(!result){
                                setValidationMsg({name: "", reason: "Neplatný kód"});
                                return;
                            }
                            setOrder(result);
                            setValidationMsg(undefined);
                    })
                }
                else{
                    setValidationMsg({name: "", reason: "Neplatný kód"})
                }
            }
            )

    }

    const handleCancelOrder = () => {
        fetcher(`/order`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"token": params.order, "code": code})
        })
            .then(response => {
               if(response.status === 200){
                   setIsCancelled(true);
               }
               else {
                   setValidationMsg({name: "", reason: "Chyba pri zruseni objednavky"});
                   setOrder({});
               }
            });
    }


    const getOrderContent = () => {
        if(isCancelled){
            return (
                <div className={"cancel-order-form-content"}>
                    Rezervace byla zrušena.
                </div>
            )
        }

        if(Object.keys(order).length > 0){
            return (
                <div className={"cancel-order-form-content"}>
                    {order.status === "ok" ? <>Potvrďte zrušení rezervace</> : <>Rezervaci již lze zrušit pouze telefonicky</>}
                    <div>
                        <span>{new Date(order["startTime"]).toLocaleString()} </span>
                        {order["productNames"] ?
                            order["productNames"].map((item: any) => {
                                return <span>- {item}</span>
                            })
                            : <></>
                        }
                    </div>
                    {order.status === "ok" ?
                        <button className={"btn"} onClick={() => handleCancelOrder()}>Zrušit rezervaci</button>
                        :
                        <></>
                    }
                </div>
            )
        }

        return (
            <div className={"cancel-order-form-content"}>
                Pro zrušení rezervace zadejte kód, který jste obdrželi v e-mailu
                <AdvancedInput
                    name={"code"}
                    value={code}
                    label={"Kod"}
                    type={"code"}
                    handleChange={(value: string) => setCode(value)}
                    validationItem={validationMsg}
                />
                <button className={"btn"} onClick={() => handleGetOrder()}>Ověřit kód</button>
            </div>
        )
    }

    if(isCancelled){

    }



    return <div className={"cancel-order-form-wrapper"}>
        <div className={"cancel-order-form-container"}>
            <div className={"cancel-order-form-header"}>
                <div><img alt={"logo"} src={"images/logo_ry_long.png"}/></div>
                <div>Zrušení Rezervace</div>
            </div>
            {getOrderContent()}
        </div>
    </div>
}

export default CancelOrderForm;