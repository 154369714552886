export interface IProductPriceOption {
    productPriceOptionId: number,
    price: number,
    duration: number
}


export interface IProductPriceProps{
    price: number,
    duration: number
}

export interface IProductPriceItemDetailProps extends IProductPriceProps{
    buyFunction: Function,
    reservable: boolean
    customValue?: string
}

export enum ProductType{
    SinglePerson = 1,
    DoublePerson = 2,
    NonReservable = 3
}


export interface IProduct {
    productId: number;
    name: string;
    shortDescription: string;
    longDescription: string;
    imagePath: string;
    price: number;
    duration: number;
    productType: ProductType,
    productPriceOptions?: IProductPriceOption[];
    selectedProductPriceOptionId?: number;
    customValue?: string;
    specialOffer?: boolean;
}


export interface IProductProps{
    product: IProduct,
    buyFunction: Function
    detailSelect: Function
}

export interface IProductDetailProps{
    product: IProduct,
    buyFunction: Function
    detailSelect: Function
}


export enum Direction{
    Left,
    Right
}

export enum AvailableDirections{
    None = 0,
    Left,
    Right,
    Both
}

export enum ProductDisplayMode {
    Overview = 0,
    Detail = 1,
    List = 2
}

export interface IDirectionalArrowsProps {
    availableDirections: AvailableDirections
    executeFunction: Function
}
