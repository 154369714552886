import {useEffect, useState} from 'react';
import {Dictionary} from 'express-serve-static-core';
import './BookingCalendar.scss';
import './BookingReactDayPicker.scss';
import {cs} from 'date-fns/locale';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {IBookingCalendarProps, IBookingTimeProps, IBookingTimeSlot} from "./models/BookingCalendarModels";
import fetcher from "../tools/Fetcher";
import LoadingBar, {LoadingBarColor} from "../tools/LoadingBar";
import {Person} from "./models/PersonSelectorModels";


const BookingTime = ({ updateFunction, date, timeSlots }: IBookingTimeProps) => {

   if (timeSlots.length === 0) {
      return <></>
   }

   return (
      <>
         {timeSlots.map((timeSlot: IBookingTimeSlot) => {
            return <div key={timeSlot.startTime.toString()} className='time-slot-item' onClick={() => updateFunction("timeSlot", timeSlot)}>
               {timeSlot.startTime.toLocaleString([], { hour: '2-digit', minute: '2-digit' })}
            </div>
         })}
      </>
   )
}


const DateComparer = (date1: Date, date2: Date): boolean => {

   return date1.getUTCDate() == date2.getUTCDate() &&
       date1.getUTCMonth() == date2.getUTCMonth() &&
       date1.getUTCFullYear() == date2.getUTCFullYear();


}

const BookingCalendar = ({ updateFunction, selectedProduct, selectedPerson, additionalSelectedPerson, increaseStepFunction }: IBookingCalendarProps) => {
   const [selectedDay, setSelectedDay] = useState<Date>();
   const [timeSlots, setTimeSlots] = useState<IBookingTimeSlot[]>([]);
   const [days, setDays] = useState<Date[]>([]);
   const [disabledDays, setDisabledDays] = useState<Date[]>([]);
   const [loading, setLoading] = useState<boolean>(true);
   const [slotsLoading, setSlotLoading] = useState<boolean>(true);


   const getDatesInRange = (startDate: Date, endDate: Date, exludeDates: Date[]) => {
      const date = new Date(startDate.getTime());
      const dates = [];

      while (date <= endDate) {

         if (!exludeDates.find(x => DateComparer(x, date))) {
            dates.push(new Date(date));
         }
         date.setDate(date.getDate() + 1);
      }

      return dates;
   }

   useEffect(() => {
       const searchParam: URLSearchParams = new URLSearchParams({
           productId: selectedProduct.productId.toString(),
           personId: selectedPerson === undefined ? "" : selectedPerson.personId.toString(),
           additionalPersonId: additionalSelectedPerson === undefined ? "" : additionalSelectedPerson.personId.toString(),
           productPriceOptionId: selectedProduct.selectedProductPriceOptionId === undefined ? "" : selectedProduct.selectedProductPriceOptionId.toString(),
       })

       setLoading(true);

      fetcher(`/timeslot/days?${searchParam}`)
         .then(respond => respond.json())
         .then(result => {
            const daysArray: Date[] = [];
            result.forEach((dayResponse: Date) => {
               const date = new Date(dayResponse);
               daysArray.push(
                  date
               );
            });

            if (daysArray.length > 0) {
               setDisabledDays(getDatesInRange(daysArray[0], daysArray[daysArray.length - 1], daysArray))
            }

            setDays(daysArray);
            if(daysArray.length > 0) {
               selectDate(daysArray[0]);
            }

            setLoading(false);
         });
   }, []);

   const loadTimeSlots = (date: Date) => {
       setSlotLoading(true);
       const searchParam: URLSearchParams = new URLSearchParams({
        date: date.toDateString(),
        productId: selectedProduct.productId.toString(),
        personId: selectedPerson === undefined ? "" : selectedPerson.personId.toString(),
        additionalPersonId: additionalSelectedPerson === undefined ? "" : additionalSelectedPerson.personId.toString(),
        productPriceOptionId: selectedProduct.selectedProductPriceOptionId === undefined ? "" : selectedProduct.selectedProductPriceOptionId.toString(),
        })

      fetcher(`/timeslot?${searchParam}`)
         .then(respond => respond.json())
         .then(result => {
            const timeSlotArray: IBookingTimeSlot[] = [];

            result.forEach((timeSlot: Dictionary<any>) => {
                const person = timeSlot["person"];
                const personObject = new Person(
                    Number(person["personId"]),
                    person["firstname"],
                    person["lastname"],
                    person["shortDescription"],
                    person["imagePath"],
                    person["personRank"])

               timeSlotArray.push({
                  timeSlotId: Number.parseInt(timeSlot["timeSlotId"]),
                  startTime: new Date(timeSlot["startTime"]),
                  endTime: new Date(timeSlot["endTime"]),
                   person: personObject
               })
            });

            setSlotLoading(false);
            setTimeSlots(timeSlotArray);
         });
   }

   const getFirstTimeSlot = () => {
      if (days.length > 0) {
         return days[0];
      }
      return undefined;
   }

   const getLastTimeSlot = () => {
      if (days.length > 0) {
         return days[days.length - 1];
      }
      return undefined;
   }

   const selectDate = (date: any) => {
      if(!date){
         return;
      }
      loadTimeSlots(date);
      setSelectedDay(date);
   }

   if(!loading && days.length == 0){
       return (
           <div className='booking-calendar-wrapper'>

               <div className='booking-calendar-container'>
                   Omlouváme se, žádný volný termín nenalezen, zkuste jinou kombinaci masáže a maséra.
               </div>
           </div>
       )
   }

   const handleSelectTime = (key: string, value: IBookingTimeSlot) => {
       if(selectedPerson?.personId == 0){
           updateFunction("person", value.person)
       }
       if(additionalSelectedPerson && additionalSelectedPerson?.personId == 0){
           if(selectedPerson != value.person) {
               updateFunction("additionalPerson", value.person)
           }
           const secondTimeSlot = timeSlots.find(x => x.startTime == value.startTime && value.person != selectedPerson);
           if(secondTimeSlot){
               updateFunction("additionalPerson", secondTimeSlot.person)
           }
       }
       updateFunction(key, value);
       increaseStepFunction();
   }

    if(loading){
        return <div className={"booking-calendar-loading-center"}>
            <LoadingBar loadingBarColor={LoadingBarColor.Red} />
        </div>
    }


   return (
      <div className='booking-calendar-wrapper'>
         <div className='booking-calendar-container'>
            <h2 className='text-center'>Vyberte datum</h2>
            <DayPicker
               fromDate={getFirstTimeSlot()}
               toDate={getLastTimeSlot()}
               className='date-picker'
               mode="single"
               selected={selectedDay}
               onSelect={selectDate}
               locale={cs}
               disabled={disabledDays}
               modifiersClassNames={{
                  selected: 'my-selected',
                  today: 'my-today',
               }}
            />
            <p className='text-center'>
               <span className='bold'>Vybran Datum:</span>{' '}
               {selectedDay?.toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit' })}
            </p>
         </div>
         <div className={"time-slot-wrapper"}>
            <h2 className='text-center'>Vyberte čas</h2>
            <div className="time-slot-container">
                {!slotsLoading ?
                   selectedDay &&
                         <BookingTime updateFunction={handleSelectTime} date={selectedDay} timeSlots={timeSlots} />
                  :
                    <div className={"time-slot-loading-wrapper"}>
                        <LoadingBar loadingBarColor={LoadingBarColor.Red} />
                    </div>
               }
            </div>
         </div>
      </div>
   );
}

export default BookingCalendar;