import "./Gdpr.scss";

const GdprView = () => {
    return(
        <div className={"gdpr"}>
            <h2>Zásady ochrany osobních údajů</h2>
            <ol>
                <li>
                    <span className={"list-header"}>Základní ustanovení</span>
                    <ol>
                        <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je Jan Nikel IČ 08856958, se sídlem Radvanice Radvanická 1126/222  (dále jen: „správce“).</li>
                        <li>Kontaktní údaje správce jsou
                            adresa: Radvanice Radvanická 1126/222
                            email: tajemstvimaserny@seznam.cz
                            telefon: +420 731 575 752
                        </li>

                        <li>3.	Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</li>
                    </ol>
                </li>

                <li>
                    <span className={"list-header"}>Zdroje a kategorie zpracovávaných osobních údajů</span>
                    <ol>
                        <li>Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.</li>
                        <li>Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy. </li>
                        <li>Při návštěvě a užívání webového rozhraní může dále docházet k získávání a ukládání některých osobních údajů prostřednictvím souborů cookies.</li>
                    </ol>
                </li>

                <li>
                    <span className={"list-header"}>Zákonný důvod a účel zpracování osobních údajů</span>
                    <ol>
                        <li>
                            Zákonným důvodem zpracování osobních údajů je
                            <ol>
                                <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,</li>
                            </ol>
                        </li>
                        <li>Učelem zpracování osobních údajů je
                            <ol>
                                <li>vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit</li>
                            </ol>
                        </li>
                        <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR</li>
                    </ol>
                </li>

                <li>
                    <span className={"list-header"}>Doba uchovávání údajů</span>
                    <ol>
                        <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů</li>
                    </ol>
                </li>

                <li>
                    <span className={"list-header"}>Vaše práva</span>
                    <ol>
                        <li>Za podmínek stanovených v GDPR máte
                            <ol>
                                <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR</li>
                                <li>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR</li>
                                <li>právo na výmaz osobních údajů dle čl. 17 GDPR. </li>
                                <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a  </li>
                                <li>právo na přenositelnost údajů dle čl. 20 GDPR. </li>
                                <li>právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. 1.2 těchto podmínek</li>
                            </ol>
                        </li>
                        <li>
                            Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.
                        </li>
                    </ol>
                </li>
                <li>
                    <span className={"list-header"}>Příjemci osobních údajů (subdodavatelé správce)</span>
                    <ol>
                        <li>
                            Příjemci osobních údajů jsou osoby
                            <ol>
                                <li>podílející se na zajištění provozu služeb (GOPAY s.r.o.)
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span className={"list-header"}>Podmínky zabezpečení osobních údajů</span>
                    <ol>
                        <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
                        <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě, zejména použitím bezpečných přístupových hesel, bezpečně uložených záloh a velmi omezenému přístupu na server </li>
                        <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
                    </ol>
                </li>
                <li>
                    <span className={"list-header"}>Závěrečná ustanovení</span>
                    <ol>
                        <li>
                            Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte
                        </li>
                        <li>
                            S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.
                        </li>
                        <li>
                            Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a.
                        </li>
                    </ol>
                </li>
            </ol>
            Tyto podmínky nabývají účinnosti dnem 25.5.2018.
        </div>
    )
}

export default GdprView;