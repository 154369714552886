import React, {useEffect, useState} from 'react';
import "./MapPageView.scss";
import {IContent} from "../common/models/ContentModels";
import fetcher from "../tools/Fetcher";

const MapPageView = () => {
    const [content, setContent] = useState<IContent[]>([]);
    const [map, setMap] = useState<number>(0);

    useEffect(() => {
        fetcher('/content/2')
            .then(response => response.json())
            .then(result => {
                setContent(result)
            })
        fetcher('/setting/map')
            .then(response => response.json())
            .then(result => {
                setMap(result)
            })

    }, []);

    const getContent = (key: string) => {
        const item = content.find(x => x.key === key)
        if(item === undefined){
            return ""
        }
        return item.value;
    }


    const getMap = () => {
        let link = "https://www.google.com/maps/place/Tajemstv%C3%AD+mas%C3%A9rny+Ostrava/@49.837289,18.2953748,17.87z/data=!4m6!3m5!1s0x4713f99f97e0fa65:0xb73b87b98ecd17a6!8m2!3d49.8371052!4d18.2949333!16s%2Fg%2F11bc7s2g4p?entry=ttu";
        let img = "images/static/map1.png";

        if(map === 0){
            link = "https://www.google.com/maps/place/Ostravsk%C3%A1+1556,+738+01+Fr%C3%BDdek-M%C3%ADstek+1/@49.6754404,18.339002,18.17z/data=!4m6!3m5!1s0x4713f7267cb156d3:0x7ec32016ca36cb7c!8m2!3d49.6755855!4d18.3397234!16s%2Fg%2F11c5mg1clb?entry=ttu";
            img = "images/static/map.png";
        }

        if(map === 2){
            link = "https://www.google.com/maps/place/Slovensk%C3%A1+2868%2F33A,+733+01+Karvin%C3%A1+1-Hranice/@49.8668271,18.552006,18.46z/data=!4m6!3m5!1s0x471155473e911f35:0x78c55e7e39dbdd64!8m2!3d49.866941!4d18.5531139!16s%2Fg%2F11c2d155rh?entry=ttu";
            img = "images/static/map2.png";
        }

        return (
            <a href={link}>
                <img alt={"Mapa"} src={img} />
            </a>
        )
    }


    return (
        <div id={"map-page"} className="map-page-view">
            <div className={"page-header"}>Kde nás najdete?</div>
            <div className={"map-page-container"}>
                <section className={"map-page-section map-img"}>
                    {getMap()}
                </section>
                <section className={"map-page-section"}>
                    <p>
                        <div className={"map-description-header"}>
                            {getContent("address_city")}
                        </div>
                        <div className={"map-description-section"}>
                            {getContent("address_street")}
                        </div>
                    </p>
                    <p>
                        <div className={"map-description-section"}>
                            {getContent("address_info")}
                        </div>
                    </p>
                </section>
            </div>
        </div>
    );
}

export default MapPageView;
