import {
    BookingWizardStep,
    IBookingWizardData,
    IBookingWizardProps,
    IBookingWizardStepMenuItemProps,
    IConfirmationFormProps,
    IConsumer,
    IConsumerDetailsProps,
    IErrorFormProps,
    IReservationItem,
    IValidationData,
    IValidationItem,
    IVoucherResponse,
    ResponseTypes,
    UnitOfValue
} from "./models/BookingWizardModels";
import {useEffect, useState} from "react";
import "./BookingWizard.scss";
import {PersonSelector} from "./PersonSelector";
import BookingCalendar from "./BookingCalendar";
import AdvancedInput, {AdvancedInputType} from "./AdvancedInput";
import AdvancedLabel from "./AdvancedLabel";
import AdvancedRadioButtons, {IAdvancedRadioButtonOptions} from "./AdvancedRadioButtons";
import fetcher from "../tools/Fetcher";
import {IProduct, IProductPriceOption, IProductPriceProps, ProductType} from "./models/ProductSelectorModels";
import PriceContainer from "./PriceContainer";
import {IPriceChange} from "./models/PriceContainerModels";
import LoadingBar, {LoadingBarColor} from "../tools/LoadingBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons"
import AdvancedCheckBox from "./AdvancedCheckBox";

const BookingWizardStepMenuItem = ({itemStep, currentStep, label, onClick}: IBookingWizardStepMenuItemProps) => {

    const handleOnClick = () => {
        if(currentStep >= itemStep) {
            onClick();
        }
    }

    const showIcon = () => {
        if(currentStep <= itemStep){
            return <></>
        }
        return <FontAwesomeIcon icon={faPenToSquare} />
    }

    if(currentStep >= itemStep){
        return <li className={"booking-wizard-step-menu-item booking-wizard-step-menu-active"} onClick={() => handleOnClick()}>
            <a><span>{label} {showIcon()}</span></a>
        </li>
    }

    return (
        <li className={"booking-wizard-step-menu-item"} onClick={() => handleOnClick()}>
            <a><span>{label} </span></a>
        </li>
    )
}

const ConsumerDetailsForm = ({wizardData, updateData, submit, validationData}: IConsumerDetailsProps) => {
    const [voucherCode, setVoucherCode] = useState<string>("");
    const [voucherValidation, setVoucherValidation] = useState<IValidationItem>();
    const [priceChanges, setPriceChanges] = useState<IPriceChange[]>([]);
    const [gdpr, setGdpr] = useState<boolean>(false);
    const [simpleVoucher, setSimpleVoucher] = useState<boolean>(true);
    const [simpleVoucherLoaded, setSimpleVoucherLoaded] = useState<boolean>(false);
    const calculateDiscount = () => {
        if(wizardData.voucher && wizardData.voucher.value){
            return getProductPrice() - wizardData.voucher.value;

        }
        return getProductPrice();
    }

    const validateVoucher = () => {
        const searchParam: URLSearchParams = new URLSearchParams({
            voucherCode: voucherCode,
            productId: wizardData.product.productId.toString(),
            productPriceOptionId: wizardData.product.selectedProductPriceOptionId === undefined ? "" : wizardData.product.selectedProductPriceOptionId.toString(),
            personId: wizardData.person ? wizardData.person.personId.toString() : "",
            startTime: wizardData.timeSlot?.startTime ? wizardData.timeSlot.startTime.toDateString() : "",
        })

        fetcher(`/voucher?${searchParam}`)
            .then(response => {
                if(response.status == 200){
                    response.json()
                        .then((result: any) => {
                            const voucher : IVoucherResponse = result;
                            setVoucherValidation(undefined);
                            updateData("voucher", voucher);

                            let value = voucher.value;
                            if(!value){
                                value = 0;
                            }

                        })
                }
                else{
                    setVoucherValidation({name: "Voucher", reason: "Neplatný kód"});
                }
            });

    }

    useEffect(() => {
        fetcher(`/setting/getAllowedSettings/AllowOtherVouchers`)
            .then(response => {
                if(response.status === 200){
                    response.json()
                        .then((result: any) => {
                            setSimpleVoucher(result);
                            setSimpleVoucherLoaded(true);
                        }
                        );
                }
            })

        if(wizardData.voucher && wizardData.voucher.value){
            setPriceChanges(
                [
                    ...priceChanges,
                    { value: -wizardData.voucher.value, reason: "Poukaz"}
                ],
            )
        }

        if(wizardData.person && wizardData.person.personRank && wizardData.person?.personRank.priceAdjustement != 0){
            let price = getProductPrice() * wizardData.person?.personRank.priceAdjustement /100;

            setPriceChanges(
                [
                    ...priceChanges,
                    { value: price, reason: wizardData.person.personRank.name}
                ],
            )
        }
    }, [wizardData.person, wizardData.timeSlot]);

    const getProductPrice = (): number => {
        const optionId = wizardData.product.selectedProductPriceOptionId;
        if(!wizardData.product.productPriceOptions || optionId === 0) {
            return wizardData.product.price
        }

        const priceOption = wizardData.product.productPriceOptions.find(priceOption => priceOption.productPriceOptionId === optionId);

        if(!priceOption){
            return wizardData.product.price;
        }

        return priceOption.price;
    }

    const getVoucherRemainingValue = (): string => {
        const voucher = wizardData.voucher;
        if(!voucher || !voucher.isUnique){
            return "";
        }
        let remainingValue = voucher.remainingValue;

        if(voucher.unitOfValue === UnitOfValue.Currency){
            if (!remainingValue){
                return "Nezbývá žádná hodnota";
            }

            remainingValue -= voucher.value ? voucher.value : 0;
            if(remainingValue <= 0){
                return "Nezbývá žádná hodnota";
            }

            return `Zbývá ${remainingValue} Kč`;
        }
        if(voucher.unitOfValue === UnitOfValue.Usage){
            if(!remainingValue || remainingValue - 1 === 0){
                return "Nezbývá žádné další použití"
            }
            return `Zbývá ${remainingValue - 1} použití`;
        }

        return "";
    }

    const getDuration = () : string => {
        const optionId = wizardData.product.selectedProductPriceOptionId;
        if(!wizardData.product.productPriceOptions || optionId === 0) {
            return wizardData.product.duration.toString()
        }

        const priceOption = wizardData.product.productPriceOptions.find(priceOption => priceOption.productPriceOptionId === optionId);

        if(!priceOption){
            return wizardData.product.duration.toString();
        }

        return priceOption.duration.toString();
    }


    const paymentRadioButtons: IAdvancedRadioButtonOptions[] = [
        {
            label: "Hotově | QR kód - osobně",
            value: 1
        },
        {
            label: "Platební kartou - online",
            value: 2,
            disabled: true
        }
    ]

    const handleSubmit = (e: any) =>{
        e.preventDefault();
        submit();
    }

    const handleChangeCheckBox = (e: any) => {
        setGdpr(e);
    }

    const renderVoucherField = () => {

        if(!simpleVoucherLoaded){
            return <></>
        }

        if(simpleVoucher){
            return (
                <AdvancedInput
                    label={"Kód dárkového poukazu"}
                    value={wizardData.otherVoucherCode ? wizardData.otherVoucherCode : ""}
                    handleChange={(value: string) => updateData("otherVoucherCode", value)}
                    name={"otherVoucherCode"}
                    type={"text"}
                    helper={"Dárkový poukaz bude zohledněn na místě"}
                />
            )
        }

        if(wizardData.voucher){
            return(
                <div>
                    <div className={"voucher-success"}>Dárkový poukaz přijat</div>
                    <div className={"voucher-code"}>{wizardData.voucher.voucherCode}</div>
                    <div className={"voucher-value"}>{getVoucherRemainingValue()}</div>
                </div>
            )
        }
        return (
            <div>
                <AdvancedInput
                    label={"Dárkový poukaz"}
                    value={voucherCode}
                    handleChange={(value: string) => setVoucherCode(value)}
                    name={"voucher"}
                    type={"text"}
                    validationItem={voucherValidation}
                />
                <button className="btn" type="button" onClick={validateVoucher}>Použít Kód</button>
            </div>
        )
    }

    return (
        <div className={"booking-wizard-consumer-detail-form"}>
            <div>
                <div className={"booking-wizard-consumer-detail-info"}>
                    <AdvancedLabel label={"Masáž"} value={wizardData.product.name} />
                    <AdvancedLabel label={"Masér"} value={wizardData.person?.name} />
                    {wizardData.additionalPerson ? <AdvancedLabel label={"druhý Masér"} value={wizardData.additionalPerson?.name} /> : <></>}
                    <AdvancedLabel label={"Termín"} value={wizardData.timeSlot?.startTime.toLocaleString()} />
                    <AdvancedLabel label={"Délka"} value={getDuration()} suffix={" minut"} />

                </div>

                <PriceContainer
                    defaultProductName={"Masáž"}
                    personId={wizardData.person?.personId}
                    productPriceOptionId={wizardData.product.selectedProductPriceOptionId}
                    productId={wizardData.product.productId}
                    startTime={wizardData.timeSlot?.startTime}
                    voucherCode={wizardData.voucher?.voucherCode}
                />
            </div>
            <form className={"booking-wizard-consumer-detail-content"}>

                <AdvancedInput
                    label={"Jméno*"}
                    value={wizardData.firstName ? wizardData.firstName : ""}
                    handleChange={(value: string) => updateData("firstName", value)}
                    name={"firstName"}
                    type={"text"}
                    autoComplete={"given-name"}
                    validationItem={validationData.items?.find(x => x.name === "firstName")}
                />
                <AdvancedInput
                    label={"Příjmení*"}
                    value={wizardData.lastName ? wizardData.lastName : ""}
                    handleChange={(value: string) => updateData("lastName", value)}
                    name={"lastName"}
                    type={"text"}
                    autoComplete={"family-name"}
                    validationItem={validationData.items?.find(x => x.name === "lastName")}
                />
                <AdvancedInput
                    label={"Email*"}
                    value={wizardData.email ? wizardData.email : ""}
                    handleChange={(value: string) => updateData("email", value)}
                    name={"email"}
                    type={"text"}
                    autoComplete={"email"}
                    validationItem={validationData.items?.find(x => x.name === "email")}
                />
                <AdvancedInput
                    label={"Telefon*"}
                    value={wizardData.phone ? wizardData.phone : ""}
                    handleChange={(value: string) => updateData("phone", value)}
                    name={"phone"}
                    type={"text"}
                    inputType={AdvancedInputType.Number}
                    autoComplete={"tel-local"}
                    validationItem={validationData.items?.find(x => x.name === "phone")}
                />
                <span className={"space-10"}></span>

                {renderVoucherField()}

                <span className={"space-10"}></span>

                <AdvancedRadioButtons
                    label={"Způsob platby"}
                    groupName={"paymentType"}
                    handleChange={(value: number) => updateData("paymentType", value)}
                    options={paymentRadioButtons}
                    validationItem={validationData.items?.find(x => x.name === "paymentType")}
                    selectedValue={wizardData.paymentType}
                />

                <AdvancedCheckBox
                    label={"Souhlasím se <a href='/gdpr' target='_blank'>zpracování osobních údajů</a>"}
                    value={"true"}
                    handleChange={handleChangeCheckBox}
                    name={"gdpr"}
                    type={"checkbox"}
                />
                <div>
                    <button className='btn' type={"submit"} onClick={handleSubmit} disabled={!gdpr}>Závazně rezervovat</button>

                </div>
                <span className="voucher-error">
                    {validationData.valid ? "" : validationData.reason}
                </span>
            </form>
        </div>
    )
}

const ConfirmationForm = ({resetForm}: IConfirmationFormProps) => {
    return (
        <div className={"booking-wizard-confirmation-form"}>
            <div>Děkujeme rezervace byla úspěšně vytvořena. Potvrzení Vám přijde emailem.</div>
            <div>
                <button className={"btn"} onClick={() => resetForm()}>Vrátit zpět</button>
            </div>
        </div>
    )
}

const ErrorForm = ({resetForm, responseType}: IErrorFormProps) => {

    const renderErrorMessage = () =>{
        if(responseType === ResponseTypes.AlreadyReserved){
            return <div>
                Daný termín je již rezervovám, prosíme zkuste jiný termín.
            </div>
        }
        return <div>
            Nastala neočekávaná chyba, zkuste akci opakovat, pokud se bude chyba opakovat, prosíme kontaktujte nás telefonicky nebo emailem.
        </div>
    }

    return (
        <div className={"booking-wizard-confirmation-form"}>
            <div>Omlouváme se, ale při zpracování vznikla chyba</div>
            {renderErrorMessage()}
            <div>
                <button className={"btn"} onClick={() => resetForm()}>Vrátit zpět</button>
            </div>
        </div>
    )
}

const BookingWizard = ({product, handleDeselectProduct}: IBookingWizardProps) => {
    const [wizardData, setWizardData] = useState<IBookingWizardData>
    (
        {product}
    )
    const [currentStep, setCurrentStep] = useState<BookingWizardStep>(BookingWizardStep.Person);
    const [formValidationData, setFormValidationData] = useState<IValidationData>({valid: true});
    const [responseType, setResponseType] = useState<ResponseTypes>(ResponseTypes.Ok);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpdateData = (name: string, value: any) => {
        setWizardData(wizardData => ({
            ...wizardData,
            [name]: value
        }));
    }

    const handleIncreaseStep = () => {
        setCurrentStep((prevState) => prevState + 1);
    }

    const scrollToTop = () => {
        let scrollTo = 0;
        const introElement = document.getElementById("intro-page");
        const newsElement = document.getElementById("news-page");
        if(document && introElement && newsElement) {
            scrollTo = introElement.scrollHeight + newsElement.scrollHeight;
        }
        window.scrollTo({top: scrollTo, behavior: "auto"})
    }

    useEffect(() => {
        let scrollTo = 0;
        const productElement = document.getElementById("products");
        scrollToTop();
        // if(productElement) {
        //     productElement.scrollIntoView();
        // }

        // const checkIfScrollToIsFinished = setInterval(() => {
        //     if (scrollTo === window.scrollY) {
        //         //document.body.style.overflow = 'hidden';
        //         clearInterval(checkIfScrollToIsFinished);
        //     }
        // }, 25);

    }, [product]);

    const getCurrentStepContent = () =>{
        if(currentStep === BookingWizardStep.Person){
            return <PersonSelector
                product={wizardData.product}
                updateFunction={handleUpdateData}
                person={wizardData.person}
                additionalPerson={wizardData.additionalPerson}
                increaseStepFunction={handleIncreaseStep}
            />
        }

        if(currentStep === BookingWizardStep.Date){
            return <BookingCalendar
                updateFunction={handleUpdateData}
                selectedProduct={wizardData.product}
                selectedPerson={wizardData.person}
                additionalSelectedPerson={wizardData.additionalPerson}
                increaseStepFunction={handleIncreaseStep}
            />
        }

        if(currentStep === BookingWizardStep.ConsumerDetails){
            return <ConsumerDetailsForm
                validationData={formValidationData}
                wizardData={wizardData}
                updateData={handleUpdateData}
                submit={handleSubmitBooking}
            />
        }

        if(currentStep === BookingWizardStep.Confirmation){
            return <ConfirmationForm resetForm={() => handleStepMenuClick(0)} />
        }

        if(currentStep === BookingWizardStep.Error){
            return <ErrorForm resetForm={() => handleStepMenuClick(0)} responseType={responseType} />
        }
    }

    const validation = (consumer: IConsumer, paymentType: number | undefined) :boolean => {
        let validationData: IValidationData = {valid: true}
        let validationItems: IValidationItem[] = [];

        if(!consumer.email || !consumer.email?.includes("@", 1)
            || !consumer.email.split("@")[1].includes(".", 1)) {

            validationItems.push({
                name: "email",
                reason: "Neplatný email"
            })
        }
        if(!consumer.firstName) {
            validationItems.push({
                name: "firstName",
                reason: "Vyplňte jméno"
            })
        }
        if(!consumer.lastName) {
            validationItems.push({
                name: "lastName",
                reason: "Vyplňte příjmení"
            })
        }
        if(!consumer.phone) {
            validationItems.push({
                name: "phone",
                reason: "Neplatný telefon"
            })
        }
        if(!paymentType){
            validationItems.push({
                name: "paymentType",
                reason: "Vyberte"
            })
        }

        validationData.items = validationItems;
        validationData.valid = validationItems.length == 0

        if(!validationData.valid){
            validationData.reason = "Vyplňte požadovana pole"
        }

        setFormValidationData(validationData);

        return validationData.valid;
    }


    const handleSubmitBooking = () => {
        // Sanitize before sending
        wizardData.consumer = {
            email: wizardData.email,
            firstName: wizardData.firstName,
            lastName: wizardData.lastName,
            phone: wizardData.phone
        }

        if(!validation(wizardData.consumer, wizardData.paymentType)){
            return;
        }

        wizardData.startTime = wizardData.timeSlot?.startTime

        let productPriceOption: IProductPriceOption | undefined = undefined

        if(wizardData.product.selectedProductPriceOptionId &&  wizardData.product.selectedProductPriceOptionId > 0) {
            productPriceOption = {
                price: 0,
                duration: 0,
                productPriceOptionId: wizardData.product.selectedProductPriceOptionId
            }
        }

        const reservationItem : IReservationItem = {
            product: wizardData.product,
            person: wizardData.person,
            productPriceOption: productPriceOption,
            startTime: wizardData.timeSlot?.startTime,
            voucher: wizardData.voucher ? wizardData.voucher : undefined,
            otherVoucherCode: wizardData.otherVoucherCode
        }

        wizardData.reservationItems = [reservationItem]

        if(wizardData.product.productType === ProductType.DoublePerson){
            if(!wizardData.additionalPerson) {
                return;
            }

            wizardData.reservationItems.push(
                {
                    person: wizardData.additionalPerson,
                    product: wizardData.product,
                    otherVoucherCode: wizardData.otherVoucherCode
                }
            )
        }

        wizardData.paymentType = Number(wizardData.paymentType);

        setLoading(true);
        fetcher("/reservation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(wizardData)
        })
            .then(response => {
                setLoading(false)
                //refreshData();
                if(response.status === 200) {
                    setCurrentStep(BookingWizardStep.Confirmation);
                    scrollToTop();
                }
                else{
                    if(response.message === "Already reserved"){
                        setResponseType(ResponseTypes.AlreadyReserved);
                    }
                    else{
                        setResponseType(ResponseTypes.Error);
                    }
                    setCurrentStep(BookingWizardStep.Error);
                }
            });
    }

    if(loading){
        return <div className={"booking-wizard-container"}>
            <div className={"booking-wizard-step-content"}>
                <LoadingBar loadingBarColor={LoadingBarColor.Red}/>
            </div>
        </div>
    }

    const handleStepMenuClick = (target: BookingWizardStep) => {
        if(target === BookingWizardStep.Product){
            document.body.style.overflow = 'auto';
            handleDeselectProduct();
        }
        else if(target === BookingWizardStep.Person){
            handleUpdateData("person", undefined);
            handleUpdateData("additionalPerson", undefined);
        }
        setCurrentStep(target);
    }

    return (
        <div className={"booking-wizard-container"}>
            {currentStep < 4 ?
                <div className={"booking-wizard-steps-menu"}>
                    <ul>
                        <BookingWizardStepMenuItem
                            currentStep={currentStep}
                            itemStep={BookingWizardStep.Product}
                            onClick={() => handleStepMenuClick(BookingWizardStep.Product)}
                            label={"Masáž"}
                        />
                        <BookingWizardStepMenuItem
                            currentStep={currentStep}
                            itemStep={BookingWizardStep.Person}
                            onClick={() => handleStepMenuClick(BookingWizardStep.Person)}
                            label={"Masér"}
                        />
                        <BookingWizardStepMenuItem
                            currentStep={currentStep}
                            itemStep={BookingWizardStep.Date}
                            onClick={() => handleStepMenuClick(BookingWizardStep.Date)}
                            label={"Termín"}
                        />
                        <BookingWizardStepMenuItem
                            currentStep={currentStep}
                            itemStep={BookingWizardStep.ConsumerDetails}
                            onClick={() => handleStepMenuClick(BookingWizardStep.ConsumerDetails)}
                            label={"Údaje"}
                        />
                    </ul>
                </div>
                :
                <></>
            }
            <div className={"booking-wizard-step-content"}>
                {getCurrentStepContent()}
            </div>
        </div>
    );
}

export default BookingWizard;