import React, {useEffect, useState} from "react";
import './NavMainHeader.scss';
import {useMediaQuery} from "react-responsive";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

interface INavMenuItemProps{
    text: string,
    link: string
}

export interface INavMenuNode {
    text: string,
    link: string
}

export interface INavMenuNodeCluster{
    navMenuNodes: INavMenuNode[];
}

export interface INavMainHeaderProps {
    navMenuTemplate: INavMenuTemplate;
}

export interface INavMenuTemplate {
    navMenuNodeClusters: INavMenuNodeCluster[];
}


const NavItem = ({text, link}: INavMenuItemProps) => {
    return (
        <li>
            <a href={link}>{text}</a>
        </li>
    );
}

const NavMainHeader = ({navMenuTemplate}: INavMainHeaderProps) => {
    const [scrolling, setScrolling] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const navMenuNodeClusters: INavMenuNodeCluster[] = navMenuTemplate.navMenuNodeClusters;
    let navClass = "desktop top";

    if(scrolling){
        navClass = "desktop scrolling";
    }
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })


    const handleScroll = () => {
        if (window.scrollY > 0){
            setScrolling(true);
        }
        else{
            setScrolling(false);
        }
    }

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const renderDesktopNav = () => {
       return <nav className={navClass}>
            <div className={"nav-section"}>
                <ul className={"menu"}>
                    {
                        navMenuNodeClusters[0].navMenuNodes.map(navMenuNode => {
                            return <NavItem link={navMenuNode.link} text={navMenuNode.text} />
                        })
                    }
                </ul>
            </div>

            <div className={"nav-section"}>
                <div className={"nav-menu-logo"}>
                    <img alt={"logo"} src={"images/logo_ry.png"}/>
                </div>
            </div>

            <div className={"nav-section"}>
                <ul className={"menu"}>
                    {
                        navMenuNodeClusters[1].navMenuNodes.map(navMenuNode => {
                            return <NavItem link={navMenuNode.link} text={navMenuNode.text} />
                        })
                    }
                </ul>
            </div>
        </nav>
    }

    const renderMobileNab = () => {
        return <nav className={"scrolling"}>
            <div className={"nav-section"}>
                <div className={"nav-menu-logo"}>
                    <img alt={"logo"} src={"images/logo_ry_long.png"}/>
                </div>
            </div>

            <div className={"nav-menu-burger"} onClick={() => setShowMenu(!showMenu)}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            {showMenu?
                <div className={"nav-menu-burger-section"}>
                    <ul className={"menu"}>
                        {
                            navMenuNodeClusters[0].navMenuNodes.map(navMenuNode => {
                                return <NavItem link={navMenuNode.link} text={navMenuNode.text} />
                            })
                        }
                        {
                            navMenuNodeClusters[1].navMenuNodes.map(navMenuNode => {
                                return <NavItem link={navMenuNode.link} text={navMenuNode.text} />
                            })
                        }
                    </ul>
                </div>
                :
                <></>
            }

        </nav>
    }

    return (
        <>
            {isTabletOrMobile ? renderMobileNab() : renderDesktopNav()}
        </>

    );
};

export default NavMainHeader;