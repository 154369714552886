import {IProduct, IProductPriceOption} from "./ProductSelectorModels";
import {Person} from "./PersonSelectorModels";
import {IBookingTimeSlot} from "./BookingCalendarModels";

export enum ResponseTypes{
    Ok = 0,
    AlreadyReserved = 1,
    Error = 2
}

export interface IBookingWizardProps{
    product: IProduct;
    handleDeselectProduct: Function;
}

export interface IVoucherResponse {
    name?: string,
    unitOfValue?: UnitOfValue,
    value?: number,
    voucherCode: string,
    remainingValue?: number,
    isUnique?: boolean
}

export interface IConsumer {
    email?: string,
    firstName?: string,
    lastName?: string,
    phone?: string
}

export interface IReservationItem {
    person?: Person,
    voucher?: IVoucherResponse,
    product?: IProduct,
    startTime?: Date,
    otherVoucherCode?: string,
    productPriceOption?: IProductPriceOption
}

export interface IBookingWizardData{
    product: IProduct,
    person?: Person,
    additionalPerson?: Person,
    timeSlot?: IBookingTimeSlot,
    voucher?: IVoucherResponse,
    voucherCode?: string,
    email?: string,
    lastName?: string,
    firstName?: string,
    phone?: string
    discount?: number,
    startTime?: Date,
    people?: any,
    products?: any,
    consumer?: IConsumer,
    paymentType?: number,
    reservationItems?: IReservationItem[],
    otherVoucherCode?: string
}


export interface IConsumerDetailsProps{
    wizardData: IBookingWizardData,
    updateData: Function,
    submit: Function,
    validationData: IValidationData
}

export interface IErrorFormProps extends IConfirmationFormProps {
    responseType: ResponseTypes
}

export interface IConfirmationFormProps{
    resetForm: Function,
}

export enum UnitOfValue {
    Currency = 1,
    Percentage = 2,
    Usage = 3
}

export enum BookingWizardStep {
    Product,
    Person,
    Date,
    ConsumerDetails,
    Confirmation,
    Error
}

export interface IBookingWizardStepMenuItemProps{
    currentStep: BookingWizardStep,
    itemStep: BookingWizardStep,
    label: string,
    onClick: Function
}

export interface IValidationItem{
    name: string,
    reason?: string
}

export interface IValidationData{
    valid: boolean
    reason?: string,
    items?: IValidationItem[]
}