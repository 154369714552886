import React, { ReactNode } from 'react';
import "./Layout.scss";
import NavMainHeader, {INavMenuTemplate, INavMenuNodeCluster, INavMenuNode} from "./NavMainHeader";

interface LayoutProps {
    children: ReactNode;
}

const menuTemplate: INavMenuTemplate = {
    navMenuNodeClusters: [
        {
            navMenuNodes: [
                {link: "#info-page", text: "Jak na masáž"},
                {link: "#person-page", text: "Naši Maséři"},
            ]
        },
        {
            navMenuNodes: [
                {link: "#map-page", text: "Kontakt"},
                {link: "#products", text: "Ceník"},
                {link: "#products", text: "Masáže"},
            ]
        }
    ]
}

function Layout(props: LayoutProps) {
  return (
    <div className={"layout-content"}>
        <NavMainHeader navMenuTemplate={menuTemplate} />
        <div className='main-content'>{props.children}</div>
    </div>
  );
}

export default Layout;
