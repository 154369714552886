import './NewsPageView.scss';
import React, {useEffect, useState} from "react";
import parse from 'html-react-parser';
import {IIntroNewsItemProps, INewsItem} from "../common/models/NewsModels";
import fetcher from "../tools/Fetcher";

const IntroNewsItem = ({newsItem}: IIntroNewsItemProps) => {

    return (
        <div className={"news-item"}>
            <div className={"news-item-header"}>
                {newsItem.header}
            </div>
            <div className={"news-item-content"}>
                {parse(newsItem.content)}
            </div>
        </div>
    )
}

const NewsPageView = () =>{
    const [news, setNews] = useState<INewsItem[]>([]);
    useEffect(() => {
        const newsArray: INewsItem[] = [];


        fetcher('/content/news/1')
            .then(response => response.json())
            .then(result => {
                newsArray.push(
                    {
                        header: result.name,
                        content: result.value
                    }
                )
                setNews(newsArray);
            })
    }, []);

    return (
        <div id="news-page" className="news-view container-center">
            {
                news.length > 0 ?
                    <div className={"news-container"}>
                        {news.map((item) => {
                            return <IntroNewsItem newsItem={item} />
                        })
                        }
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

export default NewsPageView;