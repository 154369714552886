import AdvancedInput, {AdvancedInputType} from "./AdvancedInput";
import AdvancedRadioButtons, {IAdvancedRadioButtonOptions} from "./AdvancedRadioButtons";
import AdvancedCheckBox from "./AdvancedCheckBox";
import {
    IBookingWizardData,
    IValidationData
} from "./models/BookingWizardModels";
import {useEffect, useState} from "react";
import fetcher from "../tools/Fetcher";

interface ConsumerWizardFormProps {
    firstNameValue?: string,
    lastNameValue?: string,
    phoneValue?: string,
    emailValue?: string
    voucherFormActive?: boolean
    updateData: Function,
    submit: Function
    validationData: IValidationData,
    voucherForm?: any
    submitLabel: string,
    paymentType?: number,
    deliveryEnabled?: boolean,
    deliveryType?: number,
    deliveryPickupBankPayment?: boolean,
}

const ConsumerWizardForm = ({firstNameValue, lastNameValue, phoneValue,
                                emailValue, updateData, submit, validationData,
                                submitLabel, voucherForm, paymentType, deliveryEnabled, deliveryType, deliveryPickupBankPayment}: ConsumerWizardFormProps) => {
    const [gdpr, setGdpr] = useState<boolean>(false);


    const paymentRadioButtons: IAdvancedRadioButtonOptions[] = [
        {
            label: "Hotově | QR kód - osobně",
            value: 1
        },
        {
            label: "Platební kartou - online",
            value: 2,
            disabled: true
        },
        {
            label: "Bankovním převodem",
            value: 3
        }
    ]


    const handleSubmit = (e: any) =>{
        e.preventDefault();
        submit();
    }

    const handleChangeCheckBox = (e: any) => {
        setGdpr(e);
    }

    const renderVoucherForm = () => {
        if(!voucherForm){
            return <></>
        }
        return voucherForm;
    }

    const getDeliveryOptions = (): IAdvancedRadioButtonOptions[] => {
        return [
            {
                label: "Osobně na pobočce",
                value: 1,
                disabled: !!(!deliveryPickupBankPayment && paymentType && Number(paymentType) === 3)
            },
            {
                label: "Zaslat Emailem",
                value: 2,
                disabled: !!(paymentType && Number(paymentType) === 1)
            }
        ]
    }

    return (
        <form className={"booking-wizard-consumer-detail-content"}>

            <AdvancedInput
                label={"Jméno*"}
                value={firstNameValue ? firstNameValue : ""}
                handleChange={(value: string) => updateData("firstName", value)}
                name={"firstName"}
                type={"text"}
                autoComplete={"given-name"}
                validationItem={validationData.items?.find(x => x.name === "firstName")}
            />
            <AdvancedInput
                label={"Příjmení*"}
                value={lastNameValue ? lastNameValue : ""}
                handleChange={(value: string) => updateData("lastName", value)}
                name={"lastName"}
                type={"text"}
                autoComplete={"family-name"}
                validationItem={validationData.items?.find(x => x.name === "lastName")}
            />
            <AdvancedInput
                label={"Email*"}
                value={emailValue ? emailValue : ""}
                handleChange={(value: string) => updateData("email", value)}
                name={"email"}
                type={"text"}
                autoComplete={"email"}
                validationItem={validationData.items?.find(x => x.name === "email")}
            />
            <AdvancedInput
                label={"Telefon*"}
                value={phoneValue ? phoneValue : ""}
                handleChange={(value: string) => updateData("phone", value)}
                name={"phone"}
                type={"text"}
                inputType={AdvancedInputType.Number}
                autoComplete={"tel-local"}
                validationItem={validationData.items?.find(x => x.name === "phone")}
            />
            <span className={"space-10"}></span>
            {renderVoucherForm()}
            <span className={"space-10"}></span>

            <AdvancedRadioButtons
                label={"Způsob platby"}
                groupName={"paymentType"}
                handleChange={(value: number) => updateData("paymentType", value)}
                options={paymentRadioButtons}
                validationItem={validationData.items?.find(x => x.name === "paymentType")}
                selectedValue={paymentType}
            />

            {deliveryEnabled &&
                <AdvancedRadioButtons
                    label={"Způsob dodání"}
                    groupName={"deliveryType"}
                    handleChange={(value: number) => updateData("deliveryType", value)}
                    options={getDeliveryOptions()}
                    validationItem={validationData.items?.find(x => x.name === "deliveryType")}
                    selectedValue={deliveryType}
                />
            }

            <AdvancedCheckBox
                label={"Souhlasím se <a href='/gdpr' target='_blank'>zpracování osobních údajů</a>"}
                value={"true"}
                handleChange={handleChangeCheckBox}
                name={"gdpr"}
                type={"checkbox"}
            />
            <div>
                <button className='btn' type={"submit"} onClick={handleSubmit} disabled={!gdpr}>{submitLabel}</button>

            </div>
            <span className="voucher-error">
                {validationData.valid ? "" : validationData.reason}
            </span>
        </form>
    )
}


export default ConsumerWizardForm;