import "./AdvancedRadioButtons.scss";
import {IValidationItem} from "./models/BookingWizardModels";
import {ChangeEvent, ChangeEventHandler, useEffect} from "react";


export interface IAdvancedRadioButtonOptions{
    label: string,
    description?: string,
    value: any,
    disabled?: boolean
}

interface IAdvancedRadioButtonItemProps{
    groupName: string,
    options: IAdvancedRadioButtonOptions,
    handleChange: Function,
    selectedValue?: any
}

interface IAdvancedRadioButtonsProps{
    label: string,
    groupName: string
    suffix?: string,
    prefix?: string,
    handleChange: Function,
    options: IAdvancedRadioButtonOptions[],
    validationItem?: IValidationItem,
    selectedValue?: any
}


const AdvancedRadioButtonItem = ({options, handleChange, groupName, selectedValue}: IAdvancedRadioButtonItemProps) => {
    const handleOnChange =(event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        handleChange(value);
    }

    return (
        <div>
            <label>
                <input type={"radio"} name={groupName} onChange={handleOnChange} disabled={options.disabled}  value={options.value} checked={selectedValue == options.value} />
                <span>{options.label}</span>
            </label>
        </div>
    )
}

const AdvancedRadioButtons = ({label, options, groupName, handleChange, validationItem, selectedValue}: IAdvancedRadioButtonsProps) => {
    let className = "advanced-rb-container ";

    if(validationItem){
        className += "input-error";
    }

    return (
        <div className={className}>
            <div className={"advanced-rb-label-wrapper"}>
                <div className={"advanced-rb-label"}>
                    {label}
                </div>
                <span className={"advanced-rb-error-msg"}>{validationItem?.reason}</span>
            </div>
            <div className={"advanced-rb-value"}>
                {options.map((option) => (
                    <AdvancedRadioButtonItem options={option} groupName={groupName} handleChange={handleChange} selectedValue={selectedValue} />
                ))}
            </div>

        </div>
    )
}


export default AdvancedRadioButtons;