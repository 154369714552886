import "./AdvancedLabel.scss";

interface IAdvancedLabelProps{
    label: string,
    value?: string,
    multiValue?: string[]
    suffix?: string,
    prefix?: string,
}

interface IItemValueProps{
    suffix?: string,
    prefix?: string,
    value?: string,
}

const ItemValue = ({value, suffix, prefix}: IItemValueProps) => {
    return <>
        {prefix ? <span>{prefix}</span> : <></>}{value}{suffix ? <span>{suffix}</span> : <></>}
    </>
}

const AdvancedLabel = ({label, value, suffix, prefix, multiValue}: IAdvancedLabelProps) => {

    return (
        <div className={"advanced-label-container"}>
            <div className={"advanced-label-label"}>
                {label}
            </div>
            <div className={"advanced-label-value"}>
                {value && <ItemValue suffix={suffix} value={value} prefix={prefix}/>}
                {multiValue && multiValue.map(itemValue =>
                    <ItemValue suffix={suffix} value={itemValue} prefix={prefix}/>
                )}
            </div>
        </div>
    )
}

export default AdvancedLabel;