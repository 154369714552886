import {useEffect, useState} from 'react';
import {Dictionary} from 'express-serve-static-core';
import './PersonSelector.scss'
import {IPersonRank, IPersonSelectorProps, ISelectPersonProps, Person} from "./models/PersonSelectorModels";
import fetcher from "../tools/Fetcher";
import {ProductType} from "./models/ProductSelectorModels";
import LoadingBar, {LoadingBarColor} from "../tools/LoadingBar";


export const SelectPersonItem = ({person, selectedPerson, updateFunction}: ISelectPersonProps) => {
    const imagePath = `images/${person.imagePath}`;

    const safeUpdateFunction = () => {
        if(!updateFunction){
            console.warn("PersonSelector: No Update Function registered");
            return;
        }

        updateFunction("person", person);
    }

    const renderPriceAdjustement = () => {
        if(person.personRank.priceAdjustement != 0){
            return <>
                    <span className={"person-rank-name"}>
                        {person.personRank.name}
                    </span> <span>
                        {person.personRank.priceAdjustement}%
                    </span>
                </>
        }

        return <span className={"person-rank-name"}>
                    {person.personRank.name}
                </span>
    }

    return (
        <div className='person-item' >
            <div className={"person-item-image"}>

                <img alt="" src={imagePath} />

            </div>
            <div className={"person-item-container"}>
                <div className='person-item-header'>{person.firstname}</div>
                <div className={'person-item-detail'}>
                    {renderPriceAdjustement()}
                </div>
            </div>

            {selectedPerson && selectedPerson?.personId === person.personId ?
                <div className='person-item-info'>
                    Vybrán
                </div>
                :
                <div className='person-item-info'>
                    <button className={"btn"} onClick={() => safeUpdateFunction()}>Vybrat</button>
                </div>
            }

        </div>
    )
}

const PersonSelector = ({person, additionalPerson, product, updateFunction, increaseStepFunction}: IPersonSelectorProps) => {
    const [people, setPeople] = useState<Person[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        fetcher(`/person/product/${product.productId}`)
        .then(respond => respond.json())
        .then(result => {
            const personArray: Person[] = [];

            result.forEach((person: Dictionary<any>) => {
                let personRank: IPersonRank = person["personRank"];
                personArray.push(
                    new Person(
                        Number(person["personId"]),
                        person["firstname"],
                        person["lastname"],
                        person["shortDescription"],
                        person["imagePath"],
                        personRank
                    )
                );
            });

            if(product.productType != ProductType.DoublePerson) {

                personArray.push(
                    new Person(
                        0,
                        "Kdokoliv",
                        "",
                        "",
                        "logo_ry.png",
                        {personRankId: 0, name: "", priceAdjustement: 0}
                    )
                )
            }

            setPeople(personArray);
            setLoading(false);
      });
      return () => {
        
      }
    }, [product.productId])


    const handleSelectPerson = (key: string, value: any ) => {
        if(product.productType === ProductType.DoublePerson && (person || (!person && value["personId"] != 0))){
            if(person?.personId === value["personId"]){
                return;
            }
            if(person){
                updateFunction("additionalPerson", value);
                increaseStepFunction();
                return
            }
            updateFunction(key, value);
            return;
        }

        updateFunction(key, value);
        increaseStepFunction();
    }

    if(loading){
        return <div className={"person-selector-loading-center"}><LoadingBar loadingBarColor={LoadingBarColor.Red}/></div>
    }


    return (
        <div>
            <h3>
                {product.productType === ProductType.DoublePerson ?
                    <span>Vyberte dva maséry</span>
                    :
                    <span>Vyberte maséra</span>
                }
            </h3>
            <div className='person-selector'>
                {people.map((personItem: Person) => {
                    return <SelectPersonItem key={personItem.name} selectedPerson={person} person={personItem} updateFunction={handleSelectPerson} />
                })} 
            </div>
        </div>
    )
    
}

export {PersonSelector};