import "./ProductPageView.scss";
import ProductSelector from "../common/ProductSelector";



function ProductPageView() {

    return (
        <div className='page-view products-page-view' id="products">
            <div className={"page-header"}>
                Rezervujte si masáž
            </div>

            <div className='page-content'>
                <ProductSelector />
            </div>
        </div>
        
    );
}

export default ProductPageView;
