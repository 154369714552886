

const { NODE_ENV } = process.env;

// const updateOptions = (options: any) => {
//     const update = { ...options };
//     const token = undefined;//inMemoryJWTManager.getToken();
//     if (token) {
//         update.headers = {
//             ...update.headers,
//             Authorization: `Bearer ${token}`,
//         };
//     }
//     return update;
// }

const handleResponse = (response: any) => {
    if (response.status === 403 || response.status === 401) {
        console.log("Incorrect user. Login please", 2);
        //inMemoryJWTManager.eraseToken();
        window.location.href = '/';
    }
    return response;
};

const fetcher = async (endpoint: string, options: any = undefined) => {
    if (NODE_ENV === 'development'){
        endpoint = `https://localhost:7262${endpoint}`
    }
    const response = await fetch(endpoint, options);
    return handleResponse(response);
}

export default fetcher;
