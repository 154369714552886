import "./PersonView.scss";
import {IPersonRank, Person} from "./models/PersonSelectorModels";
import React, {useEffect, useState} from "react";
import fetcher from "../tools/Fetcher";
import {Dictionary} from "express-serve-static-core";

interface PersonProps{
    person: Person
}

const PersonItem = ({person} :PersonProps) => {


    const imagePath = person.imagePath == null ? "images/logo_ry.png" : `images/${person.imagePath}`;


    return (
        <div className='person'>
            <div className={"person-profile-img"}>
                <img alt="" src={imagePath} />
            </div>
            <div className='person-info'>
                <div className='item-header'>
                    <span>{person.name}</span>
                </div>
                <div>{person.personRank.name}</div>
                <div>{person.description}</div>
            </div>
        </div>
    )
}


const PersonView = () => {
    const [persons, setPersons] = useState<Person[]>([])

    useEffect(() => {
        fetcher("/person")
            .then(respond => respond.json())
            .then(result => {
                const personArray: Person[] = [];

                result.forEach((person: Dictionary<any>) => {
                    let personRank: IPersonRank = person["personRank"];
                    personArray.push(
                        new Person(
                            Number(person["personId"]),
                            person["firstname"],
                            person["lastname"],
                            person["shortDescription"],
                            person["imagePath"],
                            personRank
                        )
                    );
                });

                setPersons(personArray);
            });

        return () => {

        }
    }, [])

    return (
        <div className='person-container' id={"person-page"}>
            <div className={"person-content"}>
                <div className={"person-page-header"}>Naši Maséři</div>
                <div className={"person-page-wrapper"}>
                    <div className={"person-page-content"}>
                        {persons.map((person: Person) => {
                            return <PersonItem key={person.name} person={person} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PersonView;