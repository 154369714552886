import "./PhotoGallery.scss"
import {useEffect, useState} from "react";
import fetcher from "../tools/Fetcher";


interface PhotoGalleryItem{
    imagePath: string
    imagePathAlternate?: string
    name: string
    url: string
}

interface PhotoGalleryItemsProps{
    photos: PhotoGalleryItem[]
}

export enum PhotoGalleryConfiguration{
    SingleRow,
    Overview
}

export interface PhotoGalleryProps{
    sectionId: number,
    configuration: PhotoGalleryConfiguration
}

const PhotoGallerySingleRow = ({photos}: PhotoGalleryItemsProps) => {

    return(
        <div className={"photo-gallery-single-row"}>
            <span className={"header"}>Spolupracujeme</span>
            <div className={"photo-gallery-singe-row-content"}>
                {photos.map(photo => {
                    return <div className={"photo-gallery-singe-row-content-item"} key={photo.imagePath}>
                        <a href={photo.url} target={"_blank"}><img alt={photo.name} src={`images/${photo.imagePath}`} /></a>
                    </div>
                })}
            </div>
        </div>
    )

}


const PhotoGalleryOverview = ({photos}: PhotoGalleryItemsProps) => {

    return(
        <div className={"photo-gallery-wrapper"}>
            <span className={"header"}>Naše prostory</span>
            <div className={"photo-gallery-overview"}>

                <div className={"photo-gallery-overview-content"}>
                    {photos.map(photo => {
                        return <div className={"photo-gallery-overview-content-item"} key={photo.imagePath}>
                                <img alt={photo.name} src={`images/${photo.imagePath}`} />
                            </div>
                    })}
                </div>
                <div className={"photo-gallery-overview-content"}>
                    {photos.map(photo => {
                        return <div className={"photo-gallery-overview-content-item"} key={photo.imagePath}>
                            <img alt={photo.name} src={`images/${photo.imagePath}`} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    )

}

const PhotoGallery = ({sectionId, configuration}: PhotoGalleryProps) => {
    const [photos, setPhotos] = useState<PhotoGalleryItem[]>([])

    useEffect(() => {

        fetcher(`/content/photo-gallery/${sectionId}`)
            .then(respond => respond.json())
            .then(result => {
                setPhotos(result);
            });

        return () => {

        }
    }, [])

    if(photos.length === 0){
        return <></>
    }

    return(
        <div className={"photo-gallery"}>
            {configuration === PhotoGalleryConfiguration.SingleRow && <PhotoGallerySingleRow photos={photos} />}
            {configuration === PhotoGalleryConfiguration.Overview && <PhotoGalleryOverview photos={photos} />}
        </div>
    )
}

export default PhotoGallery;