import "./AdvancedInput.scss";
import {ChangeEvent} from "react";
import {IValidationItem} from "./models/BookingWizardModels";

interface IAdvancedInputProps{
    label: string,
    value: string,
    handleChange: Function,
    type: string,
    name: string,
    validationItem?: IValidationItem,
    inputType?: AdvancedInputType,
    autoComplete?: string,
    helper?: string
}

export enum AdvancedInputType{
    Text = 0,
    Number = 1
}


const AdvancedInput = ({label, value, handleChange, type, name, validationItem, inputType, autoComplete, helper}: IAdvancedInputProps) => {

    const getInputType = () : AdvancedInputType => {
        if(!inputType){
            return AdvancedInputType.Text;
        }
        return inputType;
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if(value == ""){
        }
        if(getInputType() === AdvancedInputType.Number && !(/^\d+$/.test(value) || value === "")) {
            return;
        }
        handleChange(value);
    }

    let className = "advanced-input-container "

    if(validationItem){
        className += "input-error"
    }

    return (
        <div className={className}>
            <div className={"advanced-input-label-wrapper"}>
                <label>{label}</label><span className={"advanced-input-error-msg"}>{validationItem?.reason}</span>
            </div>
            <input
                type={type}
                name={name}
                id={name}
                value={value}
                onChange={handleInputChange}
                autoComplete={autoComplete ? autoComplete : "off"}
            />
            {helper &&
                <div className={"advanced-input-helper"}>
                    <span>{helper}</span>
                </div>
            }

        </div>
    )
}

export default AdvancedInput;