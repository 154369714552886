import React from 'react';
import IntroPageView from './views/IntroPageView';
import ProductPageView from './views/ProductPageView';
import Layout from '../components/Layout';
import FooterPageView from './views/FooterPageView';
import InfoPageView from './views/InfoPageView';
import MapPageView from "./views/MapPageView";
import ShopItemPageView from "./views/ShopItemPageView";
import ModalNews from "./common/ModalNews";
import WorkshopPageView from "./views/WorkshopPageView";
import NewsPageView from "./views/NewsPageView";


const Home = () => {
    return (
        <Layout>
            <ModalNews/>
            <IntroPageView />
            <NewsPageView />
            <ProductPageView />
            <ShopItemPageView />
            <InfoPageView />
            <MapPageView/>
            <FooterPageView />
        </Layout>
    );
}

export default Home;
