import "./AdvancedCheckBox.scss";
import {ChangeEvent} from "react";
import {IValidationItem} from "./models/BookingWizardModels";
import parse from "html-react-parser";

interface IAdvancedCheckBoxProps{
    label: string,
    value: string,
    handleChange: Function,
    type: string,
    name: string,
    validationItem?: IValidationItem,
    inputType?: AdvancedInputType,
    autoComplete?: string
}

export enum AdvancedInputType{
    Text = 0,
    Number = 1
}


const AdvancedCheckBox = ({label, value, handleChange, type, name, validationItem, inputType, autoComplete}: IAdvancedCheckBoxProps) => {

    const getInputType = () : AdvancedInputType => {
        if(!inputType){
            return AdvancedInputType.Text;
        }
        return inputType;
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        handleChange(value);
    }

    let className = "advanced-checkbox-container "

    if(validationItem){
        className += "input-error"
    }

    return (
        <div className={className}>
            <label>
            <input
                type={"checkbox"}
                name={name}
                id={name}
                value={value}
                onChange={handleInputChange}
            />
                {parse(label)}</label>
        </div>
    )
}

export default AdvancedCheckBox;