import React, { useState } from "react";
import './TabManager.scss'
import TabSwitch from "./TabSwitch";
import {ITabSwichItem} from "./models/TabSwitchModels";

type TabManagerProps = {
    tabContent: TabContent[]
}

interface ITabContent{
    name: string,
    content?: any
}

export class TabContent implements ITabContent{
    name: string;
    content?: any;

    public constructor(name: string){
        this.name = name;
    }
    
}

const TabManager = ({tabContent}: TabManagerProps) => {
    const [currentTab, setCurrentTab] = useState<TabContent>(tabContent[0])



    const getTabSwitches = () => {
        return tabContent.map((tab: TabContent) :ITabSwichItem => {
            return {
                label: tab.name,
                active: currentTab?.name === tab.name,
                executeFunction: () => setCurrentTab(tab)
            }
        });
    }

    return (
        <>
            <div className="tab-manager-header-container">
                <TabSwitch items={getTabSwitches()} />
            </div>
            <div className="tab-manager-content-container">
                {currentTab?.content}
            </div>
        </>
    )
}

export default TabManager;