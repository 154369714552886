import React, {useEffect, useState} from 'react';
import "./FooterPageView.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faAt} from "@fortawesome/free-solid-svg-icons"
import {faInstagram, faFacebook} from "@fortawesome/free-brands-svg-icons";
import {IContent} from "../common/models/ContentModels";
import fetcher from "../tools/Fetcher";
import parse from 'html-react-parser';

const FooterPageView = () => {
    const [content, setContent] = useState<IContent[]>([]);

    useEffect(() => {
        fetcher('/content/3')
            .then(response => response.json())
            .then(result => {
                setContent(result)
            })
    }, []);

    const getContent = (key: string) => {
        const item = content.find(x => x.key === key)
        if(item === undefined){
            return ""
        }
        return item.value;
    }

  return (
    <div className="footer-page-view container-center">
        <div className={"footer-container"}>
            <div className={"footer-content"}>
                <div className={"footer-logo footer-content-item"}>
                    <img alt={"Logo"} src={"images/logo_wh.png"}/>
                </div>
                <div className={"footer-contacts footer-content-item"}>
                    <div className={"footer-contacts-item"}>
                        <a href={`tel:${getContent("phone").replace(/\s/g,"")}`}><FontAwesomeIcon icon={faPhone}/> {getContent("phone")}</a>
                    </div>
                    <div className={"footer-contacts-item"}>
                        <a href={`mailto:${getContent("email")}`}><FontAwesomeIcon icon={faAt}/> {getContent("email")}</a>
                    </div>
                </div>
                <div className={"footer-icons footer-content-item"}>
                    <a href={getContent("instagram_link")} target={"_blank"} className={"footer-icons-item"}>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href={getContent("facebook_link")} target={"_blank"} className={"footer-icons-item"}>
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </div>
            </div>
            <div className={"footer-section-header"}>Zodpovědné osoby</div>
            <div className={"footer-content next-row"}>

                <div className={"footer-contacts-item"}>
                    {parse(getContent("responsiblePerson0"))}
                </div>
                <div className={"footer-contacts-item"}>
                    {parse(getContent("responsiblePerson1"))}
                </div>
            </div>
            <div className={"footer-content next-row top-space"}>
                <div className={"footer-contacts-item"}>
                    <a href={"/gdpr"} >Zásady ochrany osobních údajů</a>
                </div>
            </div>
        </div>
        <span className={"disclaimer"}>@Tajemství masérny</span>
    </div>
  );
}

export default FooterPageView;
