import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons"
import {useMediaQuery} from 'react-responsive';
import {
    AvailableDirections,
    Direction,
    IDirectionalArrowsProps,
    IProductPriceItemDetailProps,
    ProductDisplayMode
} from "./models/ProductSelectorModels";
import {SyntheticEvent, useEffect, useState} from "react";
import "./ShopItemSelector.scss";
import fetcher from "../tools/Fetcher";
import TabSwitch from "./TabSwitch";
import {ITabSwichItem} from "./models/TabSwitchModels";
import {IShopItem, IShopItemProps, ShopView} from "./models/ShopItemSelectorModels";
import Bucket from "./Bucket";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons/faCartShopping";


const ProductPriceItemDetail = ({price, duration, buyFunction}: IProductPriceItemDetailProps) => {
    return (
        <>
            <div className='info-container-item'><FontAwesomeIcon icon={faClock} /> {duration} min</div>
            <div className='info-container-item'><FontAwesomeIcon icon={faMoneyBill} /> {price} KČ</div>
            <div className='info-container-item'>
                <button className={"btn"} onClick={() => buyFunction()}>Rezervovat</button>
            </div>
        </>
    )
}

const DirectionalArrows = ({executeFunction, availableDirections}: IDirectionalArrowsProps) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })


    if(isTabletOrMobile){
        return <></>
    }



    const leftArrow= <div className={"product-container-wrapper-controls left"}>
            <button className={"btn-circle"} onClick={() => executeFunction(Direction.Left)}><FontAwesomeIcon icon={faChevronLeft} /></button>
        </div>


    const rightArrow = <div className={"product-container-wrapper-controls right"}>
            <button className={"btn-circle"} onClick={() => executeFunction(Direction.Right)}><FontAwesomeIcon icon={faChevronRight} /></button>
        </div>




    return (
        <>
            {availableDirections === AvailableDirections.Right || availableDirections === AvailableDirections.Both ?
                rightArrow
                :
                ""
            }

            {availableDirections === AvailableDirections.Left || availableDirections === AvailableDirections.Both ?
                leftArrow
                :
                ""
            }

        </>
    )
}



const ShopItem = ({shopItem, buyFunction, detailSelect}: IShopItemProps) => {
    const [clickBuy, setClickBuy] = useState<boolean>(false);


    const imagePath = `images/${shopItem.imagePath}`;
    //const imagePath = "images/voucher.png";
    const handleShopItemBuy = () => {
        if(clickBuy){
            return;
        }
        setClickBuy(true);
        setTimeout(() => setClickBuy(false), 2000);
        buyFunction(shopItem);
    }

    const handleImgError = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = "images/voucher.png";
    };

    const shopItemClass = shopItem.specialOffer ? 'shop-item special-offer' : 'shop-item'



    return (
        <div className={shopItemClass}>
            <div className={"shop-item-image-container"}>
                {shopItem.specialOffer && <div className={"special-offer-item"}>
                    Speciální nabídka
                </div> }
                <img alt="" src={imagePath} onError={handleImgError} onClick={() => detailSelect(shopItem)}/>
            </div>
            <div className={"shop-item-container"}>

                <div className={"shop-item-info-wrapper"}>
                    <div className='shop-item-header'>{shopItem.name}</div>
                    <div className={"shop-item-info-container"}>
                        <div className='info-container-item'><FontAwesomeIcon icon={faMoneyBill} /> {shopItem.price} KČ</div>
                    </div>
                </div>

                <div className='buy-container' >
                    {clickBuy ?
                        <span>Vloženo do košíku</span>
                        :
                        <button className={"btn"} onClick={() => handleShopItemBuy()} ><FontAwesomeIcon icon={faCartShopping} /> Do košíku</button>
                    }
                </div>
            </div>
        </div>
    )
}


const ShopItemSelector = () => {
    const [shopItems, setShopItems] = useState<IShopItem[]>([])
    const [selectedShopItems, setSelectedShopItems] = useState<IShopItem[]>([]);
    const [shopItemDetail, setShopItemDetail] = useState<IShopItem>();
    const [availableDirections, setAvailableDirections] = useState<AvailableDirections>(AvailableDirections.Right);
    const [displayMode, setDisplayMode] = useState<ShopView>(ShopView.Shop)
    const [weekendPrice, setWeekendPrice] = useState<Number>();

    const selectShopItem = (shopItem: IShopItem) => {
        const selectedShopItem = selectedShopItems.find(x => x.shopItemId === shopItem.shopItemId);
        if(selectedShopItem){
            setSelectedShopItems(
                selectedShopItems.map(item => {
                    if(item.shopItemId === shopItem.shopItemId){
                        item.count ? item.count += 1 : item.count = 1;
                    }
                    return item;
                })
            );

            return;
        }
        shopItem.count = 1;
        setSelectedShopItems([...selectedShopItems, shopItem]);
    }

    useEffect(() => {
        fetcher("/shopitem")
            .then(respond => respond.json())
            .then(result => {
                setShopItems(result);
                setShopItemDetail(result[0]);
            });

    }, [])

    const handleDeselectShopItem = () => {
        setSelectedShopItems([]);
    }

    const handleSelectDetail = (shopItem: IShopItem) => {
        setShopItemDetail(shopItem)
    }

    const updateShopItemCount = (shopItemId: number, count: number) => {
        if(Number(count) === 0){
            setSelectedShopItems(selectedShopItems.filter(x => x.shopItemId != shopItemId));
            return;
        }
        setSelectedShopItems(selectedShopItems.map(item => {
            if(item.shopItemId === shopItemId){
                item.count ? item.count = count : item.count = 1;
            }
            return item
        }))
    }

    const scroll = (direction: Direction) => {
        const shopContainerElement: any = document.getElementById('shopContainer');
        const currentScroll: number = shopContainerElement.scrollLeft;
        const scrollDelta: number = 330;
        const maxScroll: number = shopContainerElement.scrollLeftMax;
        let targetScroll: number = 0;

        if (direction === Direction.Left){
            targetScroll = currentScroll - scrollDelta;
            if (targetScroll < 0 ){
                targetScroll = 0;
                setAvailableDirections(AvailableDirections.Right);
            }
            else{
                setAvailableDirections(AvailableDirections.Both);
            }
        }
        else{
            targetScroll = currentScroll + scrollDelta;
            if(targetScroll > maxScroll){
                targetScroll = maxScroll;
                setAvailableDirections(AvailableDirections.Left);
            }
            else{
                setAvailableDirections(AvailableDirections.Both);
            }
        }

        shopContainerElement.scrollTo({left: targetScroll, behavior: "smooth"})
    }


    const content = () => {


        if(displayMode === ShopView.Bucket){
            if(shopItemDetail != null) {
                return <>
                    <Bucket
                        key={shopItemDetail.name}
                        shopItems={selectedShopItems}
                        updateShopItems={updateShopItemCount}
                    />
                </>
            }
            else{
                return ""
            }
        }

        return (
            <div id='shopContainer' className='shop-container'>
                {shopItems.map((shopItem: IShopItem) => {
                    return <ShopItem
                        key={shopItem.name}
                        shopItem={shopItem}
                        buyFunction={selectShopItem}
                        detailSelect={handleSelectDetail}
                    />
                })}
            </div>
        )
    }

    const tabSwitchItems: ITabSwichItem[] = [
        {
            executeFunction: () => setDisplayMode(ShopView.Shop),
            label: "Přehled",
            active: displayMode === ShopView.Shop
        },
        {
            executeFunction: () => setDisplayMode(ShopView.Bucket),
            label: "Košík",
            active: displayMode === ShopView.Bucket
        }

    ]


    return (
        <div className={"shop-container-wrapper"}>
            <span>
                {<TabSwitch items={tabSwitchItems} />}
            </span>
            {displayMode === ShopView.Shop ?
                <DirectionalArrows
                    availableDirections={shopItemDetail === undefined ? availableDirections : AvailableDirections.Both}
                    executeFunction={scroll}
                />
                :
                ""
            }
            {content()}
        </div>
    );
}

export default ShopItemSelector;