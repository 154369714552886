import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faChevronLeft, faChevronRight, faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons"
import {useMediaQuery} from 'react-responsive';
import {
    AvailableDirections,
    Direction,
    IDirectionalArrowsProps,
    IProduct,
    IProductDetailProps,
    IProductPriceItemDetailProps,
    IProductPriceOption,
    IProductPriceProps,
    IProductProps,
    ProductDisplayMode,
    ProductType
} from "./models/ProductSelectorModels";
import {SyntheticEvent, useEffect, useState} from "react";
import {Dictionary} from "express-serve-static-core";
import BookingWizard from "./BookingWizard";
import "./ProductSelector.scss";
import fetcher from "../tools/Fetcher";
import TabSwitch from "./TabSwitch";
import {ITabSwichItem} from "./models/TabSwitchModels";
import parse from 'html-react-parser';

const DirectionalArrows = ({executeFunction, availableDirections}: IDirectionalArrowsProps) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

    if(isTabletOrMobile){
        return <></>
    }


    const leftArrow= <div className={"product-container-wrapper-controls left"}>
        <button className={"btn-circle"} onClick={() => executeFunction(Direction.Left)}><FontAwesomeIcon icon={faChevronLeft} /></button>
    </div>


    const rightArrow = <div className={"product-container-wrapper-controls right"}>
        <button className={"btn-circle"} onClick={() => executeFunction(Direction.Right)}><FontAwesomeIcon icon={faChevronRight} /></button>
    </div>


    return (
        <>
            {availableDirections === AvailableDirections.Right || availableDirections === AvailableDirections.Both ?
                rightArrow
                :
                ""
            }

            {availableDirections === AvailableDirections.Left || availableDirections === AvailableDirections.Both ?
                leftArrow
                :
                ""
            }

        </>
    )
}

const ProductPriceItemDetail = ({price, duration, buyFunction, reservable, customValue}: IProductPriceItemDetailProps) => {
    if(!reservable){
        return (
            <>
            {customValue && <div className='info-container-item'>{customValue}</div>}

            </>
        )
    }

    return (
        <>
            <div className='info-container-item'><FontAwesomeIcon icon={faClock} /> {duration} min</div>
            <div className='info-container-item'><FontAwesomeIcon icon={faMoneyBill} /> {price} KČ</div>
            <div className='info-container-item'>
                {reservable && <button className={"btn"} onClick={() => buyFunction()}>Rezervovat</button>}
            </div>
        </>
    )
}



const ProductDetail = ({product, buyFunction, detailSelect}: IProductDetailProps) => {

    const imagePath = `images/${product.imagePath}`;

    const handleReservationOptionClick = (optionId: number) => {
        product.selectedProductPriceOptionId = optionId;
        buyFunction(product);
    }


    const handleImgError = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = "images/logo_ry.png";
        event.currentTarget.style.setProperty("width", "200px");
        event.currentTarget.style.setProperty("margin-left", "50px");
    };


    return (
        <div className={"product-detail-container"}>
            <div className={"product-detail-info-wrapper"}>
                <div className={"product-detail-info-image"}>
                    <img alt="" src={imagePath} onError={handleImgError} />
                </div>
                <div className={"product-detail-controls"}>
                    <div className={"product-detail-header"}>
                        {product.name}
                    </div>
                    <div className={"product-detail-controls"}>
                        <div className={"product-detail-item-info-wrapper"}>
                            <ProductPriceItemDetail
                                reservable={product.productType !== ProductType.NonReservable}
                                price={product.price}
                                duration={product.duration}
                                buyFunction={() => handleReservationOptionClick(0)}
                                customValue={product.customValue}
                            />

                            {product.productPriceOptions && product.productPriceOptions.length > 0 ?
                                product.productPriceOptions.map((priceOption: IProductPriceOption) => {
                                    return <ProductPriceItemDetail
                                        reservable={product.productType !== ProductType.NonReservable}
                                        price={priceOption.price} duration={priceOption.duration}
                                        buyFunction={() => handleReservationOptionClick(priceOption.productPriceOptionId)}
                                        customValue={product.customValue}

                                    />
                                })
                                : ""
                            }
                        </div>


                    </div>
                </div>
            </div>
            <div className={"product-detail-description"}>{parse(product.longDescription)}</div>

        </div>
    )
}


const ProductPriceItem = ({price, duration}: IProductPriceProps) => {
    return (
        <div className={"product-item-info-container"}>
            <div className='info-container-item'><FontAwesomeIcon icon={faClock} /> {duration} min</div>
            <div className='info-container-item'><FontAwesomeIcon icon={faMoneyBill} /> {price} KČ</div>
        </div>
    )
}


const ProductItemSimple = ({product, buyFunction, detailSelect}: IProductProps) => {

    const handleReservationOptionClick = (optionId: number) => {
        product.selectedProductPriceOptionId = optionId;
        buyFunction(product);
    }

    const renderAdditionalPrices = () => {
        if(!product.productPriceOptions){
            return <></>
        }
        return (
            <>
                {product.productPriceOptions.map(item => {
                    return <div className={"product-item-simple-price"}>{item.price} KČ | {item.duration} min
                        {product.productType !== ProductType.NonReservable && <button className={"btn slim"} onClick={() => handleReservationOptionClick(item.productPriceOptionId)}>Rezervovat</button>}
                    </div>
                    })
                }
            </>
        )

    }

    if(product.productType === ProductType.NonReservable){
        return <div className={"product-item-simple"}>
            <div className={"product-item-simple-name"}>{product.name}</div>
            <div className={"product-item-simple-price-container"}>
                {product.customValue && <div className={"product-item-simple-price"}>{product.customValue}</div>}
            </div>
        </div>
    }

    return <div className={"product-item-simple"}>
        <div className={"product-item-simple-name"}>{product.name}</div>
        <div className={"product-item-simple-price-container"}>
            <div className={"product-item-simple-price"}>{product.price} KČ | {product.duration} min
                <button className={"btn slim"} onClick={() => handleReservationOptionClick(0)}>Rezervovat</button>
            </div>
            {renderAdditionalPrices()}
        </div>
    </div>
}

const ProductItem = ({product, buyFunction, detailSelect}: IProductProps) => {
    const [durationChoose, setDurationChoose] = useState<boolean>(false);
    const [priceOptions, setPriceOptions] = useState<IProductPriceOption[]>([]);

    useEffect(() => {
        if(!product.productPriceOptions || product.productPriceOptions.length < 1){
            return;
        }
        const priceOptionsCopy = product.productPriceOptions.slice(0);
        const defaultPriceOption: IProductPriceOption = {
            productPriceOptionId: 0,
            price: product.price,
            duration: product.duration
        }

        priceOptionsCopy.unshift(defaultPriceOption)
        setPriceOptions(priceOptionsCopy);
    }, [product]);


    const imagePath = `images/${product.imagePath}`;
    const productItemClass = product.specialOffer ? 'product-item special-offer' : 'product-item'

    const handleReservationOptionClick = (optionId: number) => {

        product.selectedProductPriceOptionId = optionId;

        buyFunction(product);
        setDurationChoose(false);
    }

    const handleReservationClick = () => {
        if(priceOptions && priceOptions.length > 0){
            setDurationChoose(true);
            return;
        }

        product.selectedProductPriceOptionId = 0;
        buyFunction(product);
    }

    const handleImgError = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = "images/logo_ry.png";
        event.currentTarget.style.setProperty("width", "200px");
        event.currentTarget.style.setProperty("margin-left", "50px");
    };


    const renderPrice = () => {

        if(product.productType === ProductType.NonReservable){
            return (
                <>
                    {product.customValue &&
                        <div className={"product-item-info-wrapper"}>
                            <div className={"product-item-info-custom-value"}>
                                {product.customValue}
                            </div>
                        </div>
                    }
                    </>
            )
        }

        return (
            <div className={"product-item-info-wrapper"}>
                <ProductPriceItem price={product.price} duration={product.duration} />
                {product.productPriceOptions && product.productPriceOptions.length > 0 ?
                    product.productPriceOptions.map((priceOption: IProductPriceOption) => {
                        return  <ProductPriceItem price={priceOption.price} duration={priceOption.duration} />
                    })
                    : ""
                }
            </div>
        )
    }


    return (
        <div className={productItemClass}>
            <div className={"product-item-image-container"}>
                {product.specialOffer && <div className={"special-offer-item"}>
                    Speciální nabídka
                </div> }
                <img alt="" src={imagePath} onError={handleImgError} onClick={() => detailSelect(product)}/>
                <div className={"product-item-fader"}></div>
            </div>
            <div className={"product-item-container"}>
                <div className='product-item-header'>{product.name}</div>
                {renderPrice()}

                <div className={"product-item-description-container"}>
                    <div>{parse(product.shortDescription)}</div>
                </div>
                <div className='buy-container' >
                    {durationChoose ?

                        priceOptions.map((option: IProductPriceOption) => {
                            return <button
                                className={'btn'}
                                onClick={() => handleReservationOptionClick(option.productPriceOptionId)}>
                                    {option.duration} min
                                </button>
                        })

                        :
                        product.productType !== ProductType.NonReservable &&<button className={"btn"} onClick={() => handleReservationClick()} ><FontAwesomeIcon icon={faCalendarCheck} /> Rezervovat</button>
                    }
                </div>
            </div>
        </div>
    )
}


const ProductSelector = () => {
    const [products, setProducts] = useState<IProduct[]>([])
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    const [productDetail, setProductDetail] = useState<IProduct>();
    const [availableDirections, setAvailableDirections] = useState<AvailableDirections>(AvailableDirections.Right);
    const [displayMode, setDisplayMode] = useState<ProductDisplayMode>(ProductDisplayMode.Overview)
    const [weekendPrice, setWeekendPrice] = useState<Number>();

    const selectProduct = (product: IProduct, priceOptionId: number) => {
        setSelectedProduct(product);
    }

    useEffect(() => {
        fetcher("/product")
            .then(respond => respond.json())
            .then(result => {
                const productArray: IProduct[] = [];

                result.forEach((product: Dictionary<any>) => {
                    const priceOptions: IProductPriceOption[] = [];
                    const productPriceOptionsContainer: any[] = product["productPriceOptions"]
                    if(productPriceOptionsContainer && productPriceOptionsContainer.length > 0){
                        productPriceOptionsContainer.forEach((option: IProductPriceOption) => {
                            priceOptions.push(option);
                        })
                    }


                    productArray.push(
                        {
                            productId: Number(product["productId"]),
                            name: product["name"],
                            shortDescription:  product["shortDescription"],
                            longDescription:  product["longDescription"],
                            imagePath: product["imagePath"],
                            price: Number(product["price"]),
                            duration: Number(product["duration"]),
                            productPriceOptions: priceOptions,
                            productType: product["productType"],
                            customValue: product["customValue"],
                            specialOffer: product["specialOffer"]
                        }
                    );
                });

                setProductDetail(productArray[0]);
                setProducts(productArray);
            });

        fetcher("/setting/weekendAdjustment")
            .then(response => response.json())
            .then(result => {
                setWeekendPrice(result)
            })

        return () => {

        }
    }, [])

    const handleDeselectProduct = () => {
        setSelectedProduct(undefined);
    }

    const scrollToTop = () => {
        let scrollTo = 0;
        const introElement = document.getElementById("intro-page");
        if(document && introElement) {
            scrollTo = introElement.scrollHeight;
        }
        window.scrollTo({top: scrollTo, behavior: "auto"})
    }

    const handleSelectDetail = (product: IProduct) => {
        setDisplayMode(ProductDisplayMode.Detail);
        setProductDetail(product);
        scrollToTop();
    }

    const scroll = (direction: Direction) => {
        const productContainerElement: any = document.getElementById('productContainer');
        const currentScroll: number = productContainerElement.scrollLeft;
        const scrollDelta: number = 330;
        const maxScroll: number = productContainerElement.scrollLeftMax;
        let targetScroll: number = 0;

        if (direction === Direction.Left){
            targetScroll = currentScroll - scrollDelta;
            if (targetScroll < 0 ){
                targetScroll = 0;
                setAvailableDirections(AvailableDirections.Right);
            }
            else{
                setAvailableDirections(AvailableDirections.Both);
            }
        }
        else{
            targetScroll = currentScroll + scrollDelta;
            if(targetScroll > maxScroll){
                targetScroll = maxScroll;
                setAvailableDirections(AvailableDirections.Left);
            }
            else{
                setAvailableDirections(AvailableDirections.Both);
            }
        }

        productContainerElement.scrollTo({left: targetScroll, behavior: "smooth"})
    }


    const content = () => {

        if(selectedProduct){
            return (<>
                <BookingWizard product={selectedProduct} handleDeselectProduct={handleDeselectProduct}/>
            </>)
        }

        if(displayMode === ProductDisplayMode.Detail){
            if(productDetail != null) {
                return <>
                    <ProductDetail
                        key={productDetail.name}
                        product={productDetail}
                        buyFunction={selectProduct}
                        detailSelect={setProductDetail}
                    />
                </>
            }
            else{
                return "chyba"
            }
        }

        if(displayMode === ProductDisplayMode.List){
            return <div id='productContainer' className='product-container-simple'>
                <div className={"product-container-notice"}>
                    Víkendový příplatek za každou masáž <span>{weekendPrice ? weekendPrice.toString() : ""}</span> KČ
                </div>
                {products.map((product: IProduct) => {
                    return <ProductItemSimple
                        key={product.name}
                        product={product}
                        buyFunction={selectProduct}
                        detailSelect={setProductDetail}
                    />
                })}
            </div>
        }

        return (
            <div id='productContainer' className='product-container'>
                {products.map((product: IProduct) => {
                    return <ProductItem
                        key={product.name}
                        product={product}
                        buyFunction={selectProduct}
                        detailSelect={handleSelectDetail}
                    />
                })}
            </div>
        )
    }

    const tabSwitchItems: ITabSwichItem[] = [
        {
            executeFunction: () => setDisplayMode(ProductDisplayMode.List),
            label: "Ceník",
            active: displayMode === ProductDisplayMode.List
        },
        {
            executeFunction: () => setDisplayMode(ProductDisplayMode.Overview),
            label: "Přehled",
            active: displayMode === ProductDisplayMode.Overview
        },
        {
            executeFunction: () => setDisplayMode(ProductDisplayMode.Detail),
            label: "Detail",
            active: displayMode === ProductDisplayMode.Detail
        }

    ]


    return (
        <div className={"product-container-wrapper"}>
            <span>
                {selectedProduct !== undefined ? <></> : <TabSwitch items={tabSwitchItems} />}
            </span>
            {selectedProduct === undefined && displayMode === ProductDisplayMode.Overview ?
                <DirectionalArrows
                    availableDirections={productDetail === undefined ? availableDirections : AvailableDirections.Both}
                    executeFunction={scroll}
                />
                :
                ""
            }
            {content()}
        </div>
    );
}

export default ProductSelector;